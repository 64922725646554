@use "sass:map";

$_default-elevation: (
    1: (
        0 2px 3px rgba(0, 0, 0, .04),
        0 4px 16px rgba(0, 0, 0, .12)
    ),
    2: (
        0 4px 6px rgba(0, 0, 0, .06),
        0 4px 16px rgba(0, 0, 0, .12)
    ),
    3: (
        0 6px 8px rgba(0, 0, 0, .08),
        0 4px 16px rgba(0, 0, 0, .12)
    ),
    4: (
        0 8px 10px rgba(0, 0, 0, .12),
        0 4px 16px rgba(0, 0, 0, .12)
    ),
    5: (
        0 10px 12px rgba(0, 0, 0, .16),
        0 4px 16px rgba(0, 0, 0, .12)
    ),
    6: (
        0 12px 14px rgba(0, 0, 0, .2),
        0 4px 16px rgba(0, 0, 0, .12)
    ),
    7: (
        0 14px 16px rgba(0, 0, 0, .24),
        0 4px 16px rgba(0, 0, 0, .12)
    ),
    8: (
        0 16px 18px rgba(0, 0, 0, .28),
        0 4px 16px rgba(0, 0, 0, .12)
    ),
    9: (
        0 32px 34px rgba(0, 0, 0, .32),
        0 4px 16px rgba(0, 0, 0, .12)
    )
) !default;

/// The global default Elevation map.
/// @group elevation
$kendo-elevation: $_default-elevation !default;

$kendo-elevation: map.merge($_default-elevation, $kendo-elevation);

@function k-shadow-filter($shadow) {
    $result: '';
    @each $value in $shadow {
        $result: $result + "drop-shadow(" + $value + ") ";
    }
    @return #{$result};
}

@function k-elevation($level) {
    @return var(--kendo-elevation-#{$level}, map.get($kendo-elevation, $level));
}


@mixin kendo-elevation--styles() {
    :root {
        @each $level, $shadow in $kendo-elevation {
            --kendo-elevation-#{$level}: #{$shadow};
        }
    }
}
