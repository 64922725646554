@forward "./_border-radius.scss";
@forward "./_box-shadow.scss";
@forward "./_disabled.scss";
@forward "./_decoration.scss";
@forward "./_focus-indicator.scss";
@forward "./_gradient.scss";
@forward "./_hide-scrollbar.scss";
@forward "./_import-once.scss";

@mixin background-image( $background-image: null ) {
    @if $background-image {
        background-image: url(#{$background-image});
    }
}
