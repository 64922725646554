@use "../../mixins/index.import.scss" as *;
@use "./variables.scss" as *;

@mixin kendo-tile-layout--theme-base() {

    .k-tilelayout {
        @include fill(
            $bg: $kendo-tile-layout-bg
        );
    }

    .k-tilelayout-item:focus,
    .k-tilelayout-item.k-focus {
        @include focus-indicator($kendo-tile-layout-card-focus-shadow);
    }

    .k-layout-item-hint {
        @include fill(
            $bg: $kendo-tile-layout-hint-bg,
            $border: $kendo-tile-layout-hint-border
        );
    }

}


@mixin kendo-tile-layout--theme() {
    @include kendo-tile-layout--theme-base();
}
