@use "../../mixins/index.import.scss" as *;
@use "./variables.scss" as *;

@mixin kendo-pdf-viewer--theme-base() {

    // PDF Viewer
    .k-pdf-viewer {
        @include fill(
            $kendo-pdf-viewer-text,
            $kendo-pdf-viewer-bg,
            $kendo-pdf-viewer-border
        );


        // Toolbar
        .k-toolbar {
            @include fill(
                $kendo-pdf-viewer-toolbar-text,
                $kendo-pdf-viewer-toolbar-bg,
                $kendo-pdf-viewer-toolbar-border,
                $kendo-pdf-viewer-toolbar-gradient
            );
        }


        // Canvas
        .k-pdf-viewer-canvas {
            @include fill(
                $kendo-pdf-viewer-canvas-text,
                $kendo-pdf-viewer-canvas-bg,
                $kendo-pdf-viewer-canvas-border
            );
        }


        // Page
        .k-page {
            @include fill(
                $kendo-pdf-viewer-page-text,
                $kendo-pdf-viewer-page-bg,
                $kendo-pdf-viewer-page-border
            );
            @include box-shadow( $kendo-pdf-viewer-page-shadow );
        }

        .k-blank-page > .k-icon {
            @include fill( $color: $kendo-pdf-viewer-icon-text );
        }

        .k-canvas-wrapper .k-highlight-outline {
            &.k-hover,
            &:hover {
                stroke: $kendo-pdf-viewer-highlight-annotation-hover-border;
            }

            &.k-selected {
                stroke: $kendo-pdf-viewer-highlight-annotation-border;
            }
        }

        // Search
        .k-search-highlight {
            background-color: $kendo-pdf-viewer-search-highlight-bg;
        }

        .k-search-highlight-mark {
            @include fill( $bg: $kendo-pdf-viewer-search-highlight-mark-bg );
        }

        .k-annotation-editor-layer .k-free-text-editor .k-internal {
            color: $kendo-pdf-viewer-free-text-annotation-text;

            &:empty::before {
                color: $kendo-pdf-viewer-free-text-annotation-placeholder-text;
            }
        }

    }

    .k-pdf-viewer-canvas > .k-search-panel {
        @include fill(
            $kendo-pdf-viewer-search-panel-text,
            $kendo-pdf-viewer-search-panel-bg,
            $kendo-pdf-viewer-search-panel-border,
        );
        @include box-shadow( $kendo-pdf-viewer-search-panel-shadow );
    }

}


@mixin kendo-pdf-viewer--theme() {
    @include kendo-pdf-viewer--theme-base();
}
