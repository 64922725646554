@use "sass:map";
@use "./_functions.scss" as *;
@use "../core/_index.scss" as *;
@use "../core/functions/index.import.scss" as *;

// Tooltip

/// The vertical padding of the Tooltip.
/// @group tooltip
$kendo-tooltip-padding-y: k-spacing(1) !default;
/// The horizontal padding of the Tooltip.
/// @group tooltip
$kendo-tooltip-padding-x: k-spacing(2) !default;
/// The width of the border around the Tooltip.
/// @group tooltip
$kendo-tooltip-border-width: 0px !default;
/// The border radius of the Tooltip.
/// @group tooltip
$kendo-tooltip-border-radius: k-border-radius(md) !default;

/// The font family of the Tooltip.
/// @group tooltip
$kendo-tooltip-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the Tooltip.
/// @group tooltip
$kendo-tooltip-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the Tooltip.
/// @group tooltip
$kendo-tooltip-line-height: var( --kendo-line-height-sm, normal ) !default;

/// The font size of the Tooltip title.
/// @group tooltip
$kendo-tooltip-title-font-size: calc( ( var( --kendo-font-size, .875rem ) * 1.25 ) ) !default;
/// The line height of the Tooltip title.
/// @group tooltip
$kendo-tooltip-title-line-height: var( --kendo-line-height-xs, normal ) !default;

/// The size of the Tooltip callout.
/// @group tooltip
$kendo-tooltip-callout-size: 6px !default;

/// The default background of the Tooltip.
/// @group tooltip
$kendo-tooltip-bg: if($kendo-enable-color-system, k-color( on-app-surface ), rgba( k-contrast-legacy( $kendo-body-bg ), .75 )) !default;
/// The default text color of the Tooltip.
/// @group tooltip
$kendo-tooltip-text: if($kendo-enable-color-system, k-color( app-surface ), k-contrast-legacy( $kendo-tooltip-bg )) !default;
/// The default border color of the Tooltip.
/// @group tooltip
$kendo-tooltip-border: $kendo-tooltip-bg !default;

/// The box-shadow of the Tooltip.
/// @group tooltip
$kendo-tooltip-shadow: k-elevation(2) !default;

/// The theme colors map for the Tooltip.
/// @group tooltip
$kendo-tooltip-theme-colors: $kendo-theme-colors !default;
/// The generated theme colors map for the Tooltip.
/// @group tooltip
$kendo-tooltip-theme: tooltip-theme( $kendo-tooltip-theme-colors ) !default;

@forward "@progress/kendo-theme-core/scss/components/tooltip/_variables.scss" with (
    $kendo-tooltip-padding-y: $kendo-tooltip-padding-y,
    $kendo-tooltip-padding-x: $kendo-tooltip-padding-x,
    $kendo-tooltip-border-width: $kendo-tooltip-border-width,
    $kendo-tooltip-border-radius: $kendo-tooltip-border-radius,
    $kendo-tooltip-font-family: $kendo-tooltip-font-family,
    $kendo-tooltip-font-size: $kendo-tooltip-font-size,
    $kendo-tooltip-line-height: $kendo-tooltip-line-height,
    $kendo-tooltip-title-font-size: $kendo-tooltip-title-font-size,
    $kendo-tooltip-title-line-height: $kendo-tooltip-title-line-height,
    $kendo-tooltip-callout-size: $kendo-tooltip-callout-size,
    $kendo-tooltip-bg: $kendo-tooltip-bg,
    $kendo-tooltip-text: $kendo-tooltip-text,
    $kendo-tooltip-border: $kendo-tooltip-border,
    $kendo-tooltip-shadow: $kendo-tooltip-shadow,
    $kendo-tooltip-theme-colors: $kendo-tooltip-theme-colors,
    $kendo-tooltip-theme: $kendo-tooltip-theme
);
