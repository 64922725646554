@use "../core/_index.scss" as *;

// ColorEditor/FlatColorPicker

/// The spacer of the ColorEditor.
/// @group color-editor
$kendo-color-editor-spacer: k-spacing(3) !default;

/// The minimum width of the ColorEditor.
/// @group color-editor
$kendo-color-editor-min-width: 272px !default;
/// The minimum width of the ColorEditor.
/// @group color-editor
$kendo-color-editor-sm-min-width: 252px !default;
/// The minimum width of the ColorEditor.
/// @group color-editor
$kendo-color-editor-md-min-width: $kendo-color-editor-min-width !default;
/// The minimum width of the ColorEditor.
/// @group color-editor
$kendo-color-editor-lg-min-width: 362px !default;
/// The width of the border around the ColorEditor.
/// @group color-editor
$kendo-color-editor-border-width: 1px !default;
/// The border radius of the ColorEditor.
/// @group color-editor
$kendo-color-editor-border-radius: k-border-radius(md) !default;
/// The font family of the ColorEditor.
/// @group color-editor
$kendo-color-editor-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the ColorEditor.
/// @group color-editor
$kendo-color-editor-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the ColorEditor.
/// @group color-editor
$kendo-color-editor-line-height: var( --kendo-line-height, normal ) !default;
/// The text color of the ColorEditor.
/// @group color-editor
$kendo-color-editor-text: $kendo-component-text !default;
/// The background color of the ColorEditor.
/// @group color-editor
$kendo-color-editor-bg: $kendo-component-bg !default;
/// The border color of the ColorEditor.
/// @group color-editor
$kendo-color-editor-border: $kendo-component-border !default;

/// The border color of the focused ColorEditor.
/// @group color-editor
$kendo-color-editor-focus-border: null !default;
// TODO: does this ever get focused? Isn't it always in a popup?
/// The box shadow of the focused ColorEditor.
/// @group color-editor
$kendo-color-editor-focus-shadow: null  !default; // 1px 1px 7px 1px rgba(0, 0, 0, .3)

/// The vertical padding of the ColorEditor header.
/// @group color-editor
$kendo-color-editor-header-padding-y: $kendo-color-editor-spacer !default;
/// The horizontal padding of the ColorEditor header.
/// @group color-editor
$kendo-color-editor-header-padding-x: $kendo-color-editor-header-padding-y !default;
/// The spacing between the ColorEditor header actions.
/// @group color-editor
$kendo-color-editor-header-actions-gap: k-spacing(2) !default;
/// The vertical padding of the small ColorEditor header.
/// @group color-editor
$kendo-color-editor-sm-header-padding-y: $kendo-color-editor-header-padding-y !default;
/// The horizontal padding of the small ColorEditor header.
/// @group color-editor
$kendo-color-editor-sm-header-padding-x: $kendo-color-editor-header-padding-x !default;
/// The vertical padding of the medium ColorEditor header.
/// @group color-editor
$kendo-color-editor-md-header-padding-y: $kendo-color-editor-header-padding-y !default;
/// The horizontal padding of the medium ColorEditor header.
/// @group color-editor
$kendo-color-editor-md-header-padding-x: $kendo-color-editor-header-padding-x !default;
/// The vertical padding of the large ColorEditor header.
/// @group color-editor
$kendo-color-editor-lg-header-padding-y: k-spacing(4.5) !default;
/// The horizontal padding of the large ColorEditor header.
/// @group color-editor
$kendo-color-editor-lg-header-padding-x: k-spacing(4.5) !default;

/// The width of the ColorEditor preview.
/// @group color-editor
$kendo-color-editor-color-preview-width: 32px !default;
/// The height of the ColorEditor preview.
/// @group color-editor
$kendo-color-editor-color-preview-height: 12px !default;
/// The spacing between the colors in the ColorEditor preview.
/// @group color-editor
$kendo-color-editor-preview-gap: k-spacing(1) !default;
/// The spacing between the colors in the small ColorEditor preview.
/// @group color-editor
$kendo-color-editor-sm-preview-gap: k-spacing(0.5) !default;
/// The spacing between the colors in the medium ColorEditor preview.
/// @group color-editor
$kendo-color-editor-md-preview-gap: $kendo-color-editor-preview-gap !default;
/// The spacing between the colors in the large ColorEditor preview.
/// @group color-editor
$kendo-color-editor-lg-preview-gap: k-spacing(1) !default;

/// The width of the small ColorEditor preview.
/// @group color-editor
$kendo-color-editor-sm-color-preview-width: 34px !default;
/// The height of the small ColorEditor preview.
/// @group color-editor
$kendo-color-editor-sm-color-preview-height: 12px !default;
/// The width of the medium ColorEditor preview.
/// @group color-editor
$kendo-color-editor-md-color-preview-width: $kendo-color-editor-color-preview-width !default;
/// The height of the medium ColorEditor preview.
/// @group color-editor
$kendo-color-editor-md-color-preview-height: $kendo-color-editor-color-preview-height !default;
/// The width of the large ColorEditor preview.
/// @group color-editor
$kendo-color-editor-lg-color-preview-width: 44px !default;
/// The height of the large ColorEditor preview.
/// @group color-editor
$kendo-color-editor-lg-color-preview-height: 16px !default;

/// The vertical padding of the ColorEditor views container.
/// @group color-editor
$kendo-color-editor-views-padding-y: $kendo-color-editor-spacer !default;
/// The horizontal padding of the ColorEditor views container.
/// @group color-editor
$kendo-color-editor-views-padding-x: $kendo-color-editor-views-padding-y !default;
/// The spacing of the ColorEditor views container.
/// @group color-editor
$kendo-color-editor-views-gap: $kendo-color-editor-spacer !default;
/// The vertical padding of the small ColorEditor views container.
/// @group color-editor
$kendo-color-editor-sm-views-padding-y: $kendo-color-editor-spacer !default;
/// The horizontal padding of the small ColorEditor views container.
/// @group color-editor
$kendo-color-editor-sm-views-padding-x: $kendo-color-editor-views-padding-y !default;
/// The spacing of the small ColorEditor views container.
/// @group color-editor
$kendo-color-editor-sm-views-gap: $kendo-color-editor-spacer !default;
/// The vertical padding of the medium ColorEditor views container.
/// @group color-editor
$kendo-color-editor-md-views-padding-y: $kendo-color-editor-views-padding-y !default;
/// The horizontal padding of the medium ColorEditor views container.
/// @group color-editor
$kendo-color-editor-md-views-padding-x: $kendo-color-editor-views-padding-x !default;
/// The spacing of the medium ColorEditor views container.
/// @group color-editor
$kendo-color-editor-md-views-gap: $kendo-color-editor-views-gap !default;
/// The vertical padding of the large ColorEditor views container.
/// @group color-editor
$kendo-color-editor-lg-views-padding-y: k-spacing(4.5) !default;
/// The horizontal padding of the large ColorEditor views container.
/// @group color-editor
$kendo-color-editor-lg-views-padding-x: k-spacing(4.5) !default;
/// The spacing of the large ColorEditor views container.
/// @group color-editor
$kendo-color-editor-lg-views-gap: k-spacing(4.5) !default;

/// The outline color of the focused ColorGradient.
/// @group color-editor
$kendo-color-editor-color-gradient-focus-outline-color: rgba(0, 0, 0, .3) !default;
/// The outline width of the focused ColorGradient.
/// @group color-editor
$kendo-color-editor-color-gradient-focus-outline: 2px !default;
/// The outline offset of the focused ColorGradient.
/// @group color-editor
$kendo-color-editor-color-gradient-focus-outline-offset: 4px !default;

/// The size map of the ColorEditor.
/// @group color-editor
$kendo-color-editor-sizes: (
    sm: (
        min-width: $kendo-color-editor-sm-min-width,
        header-padding-x: $kendo-color-editor-sm-header-padding-x,
        header-padding-y: $kendo-color-editor-sm-header-padding-y,
        views-padding-x: $kendo-color-editor-sm-views-padding-x,
        views-padding-y: $kendo-color-editor-sm-views-padding-y,
        preview-gap: $kendo-color-editor-sm-preview-gap,
        preview-width: $kendo-color-editor-sm-color-preview-width,
        preview-height: $kendo-color-editor-sm-color-preview-height,

    ),
    md: (
        min-width: $kendo-color-editor-md-min-width,
        header-padding-x: $kendo-color-editor-md-header-padding-x,
        header-padding-y: $kendo-color-editor-md-header-padding-y,
        views-padding-x: $kendo-color-editor-md-views-padding-x,
        views-padding-y: $kendo-color-editor-md-views-padding-y,
        preview-gap: $kendo-color-editor-md-preview-gap,
        preview-width: $kendo-color-editor-md-color-preview-width,
        preview-height: $kendo-color-editor-md-color-preview-height,
    ),
    lg: (
        min-width: $kendo-color-editor-lg-min-width,
        header-padding-x: $kendo-color-editor-lg-header-padding-x,
        header-padding-y: $kendo-color-editor-lg-header-padding-y,
        views-padding-x: $kendo-color-editor-lg-views-padding-x,
        views-padding-y: $kendo-color-editor-lg-views-padding-y,
        preview-gap: $kendo-color-editor-lg-preview-gap,
        preview-width: $kendo-color-editor-lg-color-preview-width,
        preview-height: $kendo-color-editor-lg-color-preview-height,
    )
) !default;

@forward "@progress/kendo-theme-core/scss/components/coloreditor/_variables.scss" with (
    $kendo-color-editor-spacer: $kendo-color-editor-spacer,
    $kendo-color-editor-min-width: $kendo-color-editor-min-width,
    $kendo-color-editor-sm-min-width: $kendo-color-editor-sm-min-width,
    $kendo-color-editor-md-min-width: $kendo-color-editor-md-min-width,
    $kendo-color-editor-lg-min-width: $kendo-color-editor-lg-min-width,
    $kendo-color-editor-border-width: $kendo-color-editor-border-width,
    $kendo-color-editor-border-radius: $kendo-color-editor-border-radius,
    $kendo-color-editor-font-family: $kendo-color-editor-font-family,
    $kendo-color-editor-font-size: $kendo-color-editor-font-size,
    $kendo-color-editor-line-height: $kendo-color-editor-line-height,
    $kendo-color-editor-text: $kendo-color-editor-text,
    $kendo-color-editor-bg: $kendo-color-editor-bg,
    $kendo-color-editor-border: $kendo-color-editor-border,
    $kendo-color-editor-focus-border: $kendo-color-editor-focus-border,
    $kendo-color-editor-focus-shadow: $kendo-color-editor-focus-shadow,
    $kendo-color-editor-header-padding-y: $kendo-color-editor-header-padding-y,
    $kendo-color-editor-header-padding-x: $kendo-color-editor-header-padding-x,
    $kendo-color-editor-header-actions-gap: $kendo-color-editor-header-actions-gap,
    $kendo-color-editor-sm-header-padding-y: $kendo-color-editor-sm-header-padding-y,
    $kendo-color-editor-sm-header-padding-x: $kendo-color-editor-sm-header-padding-x,
    $kendo-color-editor-md-header-padding-y: $kendo-color-editor-md-header-padding-y,
    $kendo-color-editor-md-header-padding-x: $kendo-color-editor-md-header-padding-x,
    $kendo-color-editor-lg-header-padding-y: $kendo-color-editor-lg-header-padding-y,
    $kendo-color-editor-lg-header-padding-x: $kendo-color-editor-lg-header-padding-x,
    $kendo-color-editor-color-preview-width: $kendo-color-editor-color-preview-width,
    $kendo-color-editor-color-preview-height: $kendo-color-editor-color-preview-height,
    $kendo-color-editor-preview-gap: $kendo-color-editor-preview-gap,
    $kendo-color-editor-sm-preview-gap: $kendo-color-editor-sm-preview-gap,
    $kendo-color-editor-md-preview-gap: $kendo-color-editor-md-preview-gap,
    $kendo-color-editor-lg-preview-gap: $kendo-color-editor-lg-preview-gap,
    $kendo-color-editor-sm-color-preview-width: $kendo-color-editor-sm-color-preview-width,
    $kendo-color-editor-sm-color-preview-height: $kendo-color-editor-sm-color-preview-height,
    $kendo-color-editor-md-color-preview-width: $kendo-color-editor-md-color-preview-width,
    $kendo-color-editor-md-color-preview-height: $kendo-color-editor-md-color-preview-height,
    $kendo-color-editor-lg-color-preview-width: $kendo-color-editor-lg-color-preview-width,
    $kendo-color-editor-lg-color-preview-height: $kendo-color-editor-lg-color-preview-height,
    $kendo-color-editor-views-padding-y: $kendo-color-editor-views-padding-y,
    $kendo-color-editor-views-padding-x: $kendo-color-editor-views-padding-x,
    $kendo-color-editor-views-gap: $kendo-color-editor-views-gap,
    $kendo-color-editor-sm-views-padding-y: $kendo-color-editor-sm-views-padding-y,
    $kendo-color-editor-sm-views-padding-x: $kendo-color-editor-sm-views-padding-x,
    $kendo-color-editor-sm-views-gap: $kendo-color-editor-sm-views-gap,
    $kendo-color-editor-md-views-padding-y: $kendo-color-editor-md-views-padding-y,
    $kendo-color-editor-md-views-padding-x: $kendo-color-editor-md-views-padding-x,
    $kendo-color-editor-md-views-gap: $kendo-color-editor-md-views-gap,
    $kendo-color-editor-lg-views-padding-y: $kendo-color-editor-lg-views-padding-y,
    $kendo-color-editor-lg-views-padding-x: $kendo-color-editor-lg-views-padding-x,
    $kendo-color-editor-lg-views-gap: $kendo-color-editor-lg-views-gap,
    $kendo-color-editor-color-gradient-focus-outline-color: $kendo-color-editor-color-gradient-focus-outline-color,
    $kendo-color-editor-color-gradient-focus-outline: $kendo-color-editor-color-gradient-focus-outline,
    $kendo-color-editor-color-gradient-focus-outline-offset: $kendo-color-editor-color-gradient-focus-outline-offset,
    $kendo-color-editor-sizes: $kendo-color-editor-sizes
);
