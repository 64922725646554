@use "../core/_index.scss" as *;

// Loader

/// The border radius of the Loader segment.
/// @group loader
$kendo-loader-segment-border-radius: 50% !default;

/// The size of the small Loader segment.
/// @group loader
$kendo-loader-sm-segment-size: k-spacing(1) !default;
/// The size of the medium Loader segment.
/// @group loader
$kendo-loader-md-segment-size: k-spacing(2) !default;
/// The size of the large Loader segment.
/// @group loader
$kendo-loader-lg-segment-size: k-spacing(4) !default;

/// The padding of the small Loader.
/// @group loader
$kendo-loader-sm-padding: calc( #{$kendo-loader-sm-segment-size} / 2 ) !default;
/// The padding of the medium Loader.
/// @group loader
$kendo-loader-md-padding: calc( #{$kendo-loader-md-segment-size} / 2 ) !default;
/// The padding of the large Loader.
/// @group loader
$kendo-loader-lg-padding: calc( #{$kendo-loader-lg-segment-size} / 2 ) !default;

/// The width of the small spinner-3 Loader.
/// @group loader
$kendo-loader-sm-spinner-3-width: calc( #{$kendo-loader-sm-segment-size} * 4 ) !default;
/// The width of the medium spinner-3 Loader.
/// @group loader
$kendo-loader-md-spinner-3-width: calc( #{$kendo-loader-md-segment-size} * 4 ) !default;
/// The width of the large spinner-3 Loader.
/// @group loader
$kendo-loader-lg-spinner-3-width: calc( #{$kendo-loader-lg-segment-size} * 4 ) !default;

/// The height of the small spinner-3 Loader.
/// @group loader
$kendo-loader-sm-spinner-3-height: calc( #{$kendo-loader-sm-spinner-3-width} * #{$equilateral-height} ) !default;
/// The height of the medium spinner-3 Loader.
/// @group loader
$kendo-loader-md-spinner-3-height: calc( #{$kendo-loader-md-spinner-3-width} * #{$equilateral-height} ) !default;
/// The height of the large spinner-3 Loader.
/// @group loader
$kendo-loader-lg-spinner-3-height: calc( #{$kendo-loader-lg-spinner-3-width} * #{$equilateral-height} ) !default;

/// The width of the small spinner-4 Loader.
/// @group loader
$kendo-loader-sm-spinner-4-width: calc( #{$kendo-loader-sm-segment-size} * 4 ) !default;
/// The width of the medium spinner-4 Loader.
/// @group loader
$kendo-loader-md-spinner-4-width: calc( #{$kendo-loader-md-segment-size} * 4 ) !default;
/// The width of the large spinner-4 Loader.
/// @group loader
$kendo-loader-lg-spinner-4-width: calc( #{$kendo-loader-lg-segment-size} * 4 ) !default;

/// The height of the small spinner-4 Loader.
/// @group loader
$kendo-loader-sm-spinner-4-height: $kendo-loader-sm-spinner-4-width !default;
/// The height of the medium spinner-4 Loader.
/// @group loader
$kendo-loader-md-spinner-4-height: $kendo-loader-md-spinner-4-width !default;
/// The height of the large spinner-4 Loader.
/// @group loader
$kendo-loader-lg-spinner-4-height: $kendo-loader-lg-spinner-4-width !default;

/// The border width of the container panel.
/// @group loader
$kendo-loader-container-panel-border-width: 1px !default;
/// The border style of the container panel.
/// @group loader
$kendo-loader-container-panel-border-style: solid !default;
/// The border color of the container panel.
/// @group loader
$kendo-loader-container-panel-border-color: $kendo-component-border !default;
/// The border radius of the container panel.
/// @group loader
$kendo-loader-container-panel-border-radius: k-border-radius(md) !default;
/// The background color of the container panel.
/// @group loader
$kendo-loader-container-panel-bg: $kendo-color-white !default;

/// The padding of the small Loader container.
/// @group loader
$kendo-loader-sm-container-padding: k-spacing(4) !default;
/// The padding of the medium Loader container.
/// @group loader
$kendo-loader-md-container-padding: k-spacing(5) !default;
/// The padding of the large Loader container.
/// @group loader
$kendo-loader-lg-container-padding: k-spacing(6) !default;

/// The gap of the small Loader container.
/// @group loader
$kendo-loader-sm-container-gap: k-spacing(1) !default;
/// The gap of the medium Loader container.
/// @group loader
$kendo-loader-md-container-gap: k-spacing(2) !default;
/// The gap of the large Loader container.
/// @group loader
$kendo-loader-lg-container-gap: k-spacing(3) !default;

/// The font size of the small Loader container.
/// @group loader
$kendo-loader-sm-container-font-size: var( --kendo-font-size-sm, inherit ) !default;
/// The font size of the medium Loader container.
/// @group loader
$kendo-loader-md-container-font-size: var( --kendo-font-size, inherit ) !default;
/// The font size of the large Loader container.
/// @group loader
$kendo-loader-lg-container-font-size: var( --kendo-font-size-lg, inherit ) !default;

// Loading indicator
/// The background color of the Loading indicator.
/// @group loading
$kendo-loading-bg: $kendo-component-bg !default;
/// The text color of the Loading indicator.
/// @group loading
$kendo-loading-text: currentColor !default;
/// The opacity of the Loading indicator.
/// @group loading
$kendo-loading-opacity: .3 !default;
/// The z-index of the Loading indicator.
/// @group loading
$kendo-zindex-loading: 100 !default;

@forward "@progress/kendo-theme-core/scss/components/loader/_variables.scss" with (
    $kendo-loader-segment-border-radius: $kendo-loader-segment-border-radius,
    $kendo-loader-sm-segment-size: $kendo-loader-sm-segment-size,
    $kendo-loader-md-segment-size: $kendo-loader-md-segment-size,
    $kendo-loader-lg-segment-size: $kendo-loader-lg-segment-size,
    $kendo-loader-sm-padding: $kendo-loader-sm-padding,
    $kendo-loader-md-padding: $kendo-loader-md-padding,
    $kendo-loader-lg-padding: $kendo-loader-lg-padding,
    $kendo-loader-sm-spinner-3-width: $kendo-loader-sm-spinner-3-width,
    $kendo-loader-md-spinner-3-width: $kendo-loader-md-spinner-3-width,
    $kendo-loader-lg-spinner-3-width: $kendo-loader-lg-spinner-3-width,
    $kendo-loader-sm-spinner-3-height: $kendo-loader-sm-spinner-3-height,
    $kendo-loader-md-spinner-3-height: $kendo-loader-md-spinner-3-height,
    $kendo-loader-lg-spinner-3-height: $kendo-loader-lg-spinner-3-height,
    $kendo-loader-sm-spinner-4-width: $kendo-loader-sm-spinner-4-width,
    $kendo-loader-md-spinner-4-width: $kendo-loader-md-spinner-4-width,
    $kendo-loader-lg-spinner-4-width: $kendo-loader-lg-spinner-4-width,
    $kendo-loader-sm-spinner-4-height: $kendo-loader-sm-spinner-4-height,
    $kendo-loader-md-spinner-4-height: $kendo-loader-md-spinner-4-height,
    $kendo-loader-lg-spinner-4-height: $kendo-loader-lg-spinner-4-height,
    $kendo-loader-container-panel-border-width: $kendo-loader-container-panel-border-width,
    $kendo-loader-container-panel-border-style: $kendo-loader-container-panel-border-style,
    $kendo-loader-container-panel-border-color: $kendo-loader-container-panel-border-color,
    $kendo-loader-container-panel-border-radius: $kendo-loader-container-panel-border-radius,
    $kendo-loader-container-panel-bg: $kendo-loader-container-panel-bg,
    $kendo-loader-sm-container-padding: $kendo-loader-sm-container-padding,
    $kendo-loader-md-container-padding: $kendo-loader-md-container-padding,
    $kendo-loader-lg-container-padding: $kendo-loader-lg-container-padding,
    $kendo-loader-sm-container-gap: $kendo-loader-sm-container-gap,
    $kendo-loader-md-container-gap: $kendo-loader-md-container-gap,
    $kendo-loader-lg-container-gap: $kendo-loader-lg-container-gap,
    $kendo-loader-sm-container-font-size: $kendo-loader-sm-container-font-size,
    $kendo-loader-md-container-font-size: $kendo-loader-md-container-font-size,
    $kendo-loader-lg-container-font-size: $kendo-loader-lg-container-font-size,
    $kendo-loading-bg: $kendo-loading-bg,
    $kendo-loading-text: $kendo-loading-text,
    $kendo-loading-opacity: $kendo-loading-opacity,
    $kendo-zindex-loading: $kendo-zindex-loading
);
