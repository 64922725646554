@use "../../mixins/index.import.scss" as *;
@use "./_variables.scss" as *;
@use "../menu/_variables.scss" as *;

@mixin kendo-toolbar--theme-base() {

    // Theme
    .k-toolbar {
        // Template item
        .k-toolbar-item:focus,
        .k-toolbar-item.k-focus {
            @include focus-indicator( $kendo-toolbar-item-shadow );
        }


        // Button group
        .k-button-group {}

    }

    .k-toolbar-items {
        border-color: inherit;
    }

    // Separator
    .k-toolbar .k-separator,
    .k-toolbar-separator {
        border-color: $kendo-toolbar-separator-border;
    }

    // Solid Toolbar
    .k-toolbar-solid {
        @include fill(
            $kendo-toolbar-text,
            $kendo-toolbar-bg,
            $kendo-toolbar-border,
            $kendo-toolbar-gradient
        );
        @include box-shadow( $kendo-toolbar-shadow );

        &.k-toolbar-scrollable-overlay {
            &::before {
                background: linear-gradient(90deg, $kendo-toolbar-scroll-overlay);
            }

            &::after {
                background: linear-gradient(270deg, $kendo-toolbar-scroll-overlay);
            }
        }
    }

    .k-toolbar-items-list-solid {
        border-color: $kendo-toolbar-border;
    }

    // Outline Toolbar
    .k-toolbar-outline {
        @include fill(
            $color: $kendo-toolbar-outline-text,
            $border: $kendo-toolbar-outline-border
        );
        @include box-shadow( $kendo-toolbar-outline-shadow );

        &.k-toolbar-scrollable-overlay {
            &::before {
                background: linear-gradient(90deg, $kendo-toolbar-outline-scroll-overlay);
            }

            &::after {
                background: linear-gradient(270deg, $kendo-toolbar-outline-scroll-overlay);
            }
        }
    }

    .k-toolbar-items-list-outline {
        border-color: $kendo-toolbar-outline-border;
    }

    // Flat Toolbar
    .k-toolbar-flat {
        @include fill(
            $color: $kendo-toolbar-flat-text,
            $border: $kendo-toolbar-flat-border
        );
        @include box-shadow( $kendo-toolbar-flat-shadow );


        > .k-separator,
        .k-toolbar-separator {
            border-inline-color: $kendo-toolbar-flat-border;
        }

        &.k-toolbar-scrollable-overlay {
            &::before {
                background: linear-gradient(90deg, $kendo-toolbar-flat-scroll-overlay);
            }

            &::after {
                background: linear-gradient(270deg, $kendo-toolbar-flat-scroll-overlay);
            }
        }
    }

    .k-toolbar-items-list-flat {
        border-color: $kendo-toolbar-flat-border;
    }

    .k-floating-toolbar,
    .editorToolbarWindow.k-window-content { // stylelint-disable-line
        @include fill(
            $kendo-toolbar-text,
            $kendo-toolbar-bg,
            $kendo-toolbar-border,
            $kendo-toolbar-gradient
        );
    }

    // Overflow container
    .k-overflow-container {

        .k-button {

            // Hover state
            &:hover,
            &.k-hover {
                color: $kendo-menu-popup-item-hover-text;
                background: $kendo-menu-popup-item-hover-bg;
            }

            // Button focus state
            &:focus,
            &.k-focus {
                @include focus-indicator( $kendo-menu-popup-item-focus-shadow, true );
            }

            // Active state
            &:active,
            &.k-active {
                color: $kendo-menu-popup-item-hover-text;
                background: $kendo-menu-popup-item-hover-bg;
            }

            // Selected
            &.k-selected {
                color: $kendo-menu-popup-item-active-text;
                background: $kendo-menu-popup-item-active-bg;
            }

            // Disabled state
            &:disabled,
            &.k-disabled {
                color: inherit;
            }
        }

    }

    // RTL
    :is(.k-rtl .k-toolbar-scrollable-overlay, [dir="rtl"] .k-toolbar-scrollable-overlay, .k-toolbar-scrollable-overlay.k-rtl, .k-toolbar-scrollable-overlay[dir="rtl"] ) {
        &::before {
            background: linear-gradient(270deg, $kendo-toolbar-scroll-overlay);
        }

        &::after {
            background: linear-gradient(90deg, $kendo-toolbar-scroll-overlay);
        }

        &.k-toolbar-outline {
            &::before {
                background: linear-gradient(270deg, $kendo-toolbar-outline-scroll-overlay);
            }

            &::after {
                background: linear-gradient(90deg, $kendo-toolbar-outline-scroll-overlay);
            }
        }

        &.k-toolbar-flat {
            &::before {
                background: linear-gradient(270deg, $kendo-toolbar-flat-scroll-overlay);
            }

            &::after {
                background: linear-gradient(90deg, $kendo-toolbar-flat-scroll-overlay);
            }
        }
    }
}


@mixin kendo-toolbar--theme() {
    @include kendo-toolbar--theme-base();
}
