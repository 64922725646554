@use "../core/_index.scss" as *;
@use "../icons/_variables.scss" as *;

// Breadcrumb

/// The width of the border around the Breadcrumb.
/// @group breadcrumb
$kendo-breadcrumb-border-width: 0px !default;

/// The horizontal margin of the Breadcrumb.
/// @group breadcrumb
$kendo-breadcrumb-margin-x: null !default;
/// The vertical margin of the Breadcrumb.
/// @group breadcrumb
$kendo-breadcrumb-margin-y: null !default;
/// The horizontal padding of the Breadcrumb.
/// @group breadcrumb
$kendo-breadcrumb-padding-x: null !default;
/// The vertical padding of the Breadcrumb.
/// @group breadcrumb
$kendo-breadcrumb-padding-y: null !default;

/// The font family of the Breadcrumb.
/// @group breadcrumb
$kendo-breadcrumb-font-family: var( --kendo-font-family, inherit ) !default;

/// The font size of the Breadcrumb.
/// @group breadcrumb
$kendo-breadcrumb-font-size: var( --kendo-font-size, inherit ) !default;
/// The font size of the small Breadcrumb.
/// @group breadcrumb
$kendo-breadcrumb-sm-font-size: var( --kendo-font-size, inherit ) !default;
/// The font size of the medium Breadcrumb.
/// @group breadcrumb
$kendo-breadcrumb-md-font-size: $kendo-breadcrumb-font-size !default;
/// The font size of the large Breadcrumb.
/// @group breadcrumb
$kendo-breadcrumb-lg-font-size: var( --kendo-font-size-lg, inherit ) !default;

/// The line-height of the Breadcrumb.
/// @group breadcrumb
$kendo-breadcrumb-line-height: var( --kendo-line-height, normal ) !default;
/// The line-height of the small Breadcrumb.
/// @group breadcrumb
$kendo-breadcrumb-sm-line-height: var( --kendo-line-height, normal ) !default;
/// The line-height of the medium Breadcrumb.
/// @group breadcrumb
$kendo-breadcrumb-md-line-height: $kendo-breadcrumb-line-height !default;
/// The line-height of the height Breadcrumb.
/// @group breadcrumb
$kendo-breadcrumb-lg-line-height: var( --kendo-line-height-lg, normal ) !default;

/// The base background of the Breadcrumb.
/// @group breadcrumb
$kendo-breadcrumb-bg: $kendo-component-bg !default;
/// The base text color of the Breadcrumb.
/// @group breadcrumb
$kendo-breadcrumb-text: $kendo-component-text !default;
/// The base border color of the Breadcrumb.
/// @group breadcrumb
$kendo-breadcrumb-border: $kendo-component-border !default;

/// The box shadow of the focused Breadcrumb.
/// @group breadcrumb
$kendo-breadcrumb-focus-shadow: 0 0 2px 1px rgba(0, 0, 0, .06) !default;

/// The horizontal padding of the Breadcrumb link.
/// @group breadcrumb
$kendo-breadcrumb-link-padding-x: k-spacing(2) !default;
/// The horizontal padding of the small Breadcrumb link.
/// @group breadcrumb
$kendo-breadcrumb-sm-link-padding-x: k-spacing(2) !default;
/// The horizontal padding of the medium Breadcrumb link.
/// @group breadcrumb
$kendo-breadcrumb-md-link-padding-x: $kendo-breadcrumb-link-padding-x !default;
/// The horizontal padding of the large Breadcrumb link.
/// @group breadcrumb
$kendo-breadcrumb-lg-link-padding-x: k-spacing(2) !default;

/// The vertical padding of the Breadcrumb link.
/// @group breadcrumb
$kendo-breadcrumb-link-padding-y: k-spacing(1.5) !default;
/// The vertical padding of the small Breadcrumb link.
/// @group breadcrumb
$kendo-breadcrumb-sm-link-padding-y: k-spacing(1) !default;
/// The vertical padding of the medium Breadcrumb link.
/// @group breadcrumb
$kendo-breadcrumb-md-link-padding-y: $kendo-breadcrumb-link-padding-y !default;
/// The vertical padding of the large Breadcrumb link.
/// @group breadcrumb
$kendo-breadcrumb-lg-link-padding-y: k-spacing(2) !default;

/// The border-radius of the Breadcrumb link.
/// @group breadcrumb
$kendo-breadcrumb-link-border-radius: k-border-radius(md) !default;

/// The vertical padding of the Breadcrumb link icon.
/// @group breadcrumb
$kendo-breadcrumb-icon-link-padding-y: k-spacing(2) !default;
/// The vertical padding of the small Breadcrumb link icon.
/// @group breadcrumb
$kendo-breadcrumb-sm-icon-link-padding-y: k-spacing(1.5) !default;
/// The vertical padding of the medium Breadcrumb link icon.
/// @group breadcrumb
$kendo-breadcrumb-md-icon-link-padding-y: $kendo-breadcrumb-icon-link-padding-y !default;
/// The vertical padding of the large Breadcrumb link icon.
/// @group breadcrumb
$kendo-breadcrumb-lg-icon-link-padding-y: k-spacing(3) !default;

/// The horizontal padding of the Breadcrumb link icon.
/// @group breadcrumb
$kendo-breadcrumb-icon-link-padding-x: $kendo-breadcrumb-icon-link-padding-y !default;
/// The horizontal padding of the small Breadcrumb link icon.
/// @group breadcrumb
$kendo-breadcrumb-sm-icon-link-padding-x: $kendo-breadcrumb-sm-icon-link-padding-y !default;
/// The horizontal padding of the medium Breadcrumb link icon.
/// @group breadcrumb
$kendo-breadcrumb-md-icon-link-padding-x: $kendo-breadcrumb-icon-link-padding-x !default;
/// The horizontal padding of the large Breadcrumb link icon.
/// @group breadcrumb
$kendo-breadcrumb-lg-icon-link-padding-x: $kendo-breadcrumb-lg-icon-link-padding-y !default;

/// The text color of the Breadcrumb link.
/// @group breadcrumb
$kendo-breadcrumb-link-initial-text: inherit !default;
/// The spacing of the Breadcrumb link icon.
/// @group breadcrumb
$kendo-breadcrumb-link-icon-spacing: $kendo-icon-spacing !default;

/// The background color of the Breadcrumb link.
/// @group breadcrumb
$kendo-breadcrumb-link-bg: null !default;
/// The text color of the Breadcrumb link.
/// @group breadcrumb
$kendo-breadcrumb-link-text: $kendo-link-text !default;
/// The border color of the Breadcrumb link.
/// @group breadcrumb
$kendo-breadcrumb-link-border: null !default;

/// The background color of the hovered Breadcrumb link.
/// @group breadcrumb
$kendo-breadcrumb-link-hover-bg: $kendo-base-bg !default;
/// The text color of the hovered Breadcrumb link.
/// @group breadcrumb
$kendo-breadcrumb-link-hover-text: $kendo-link-hover-text !default;
/// The border color of the hovered Breadcrumb link.
/// @group breadcrumb
$kendo-breadcrumb-link-hover-border: null !default;

/// The background color of the focused Breadcrumb link.
/// @group breadcrumb
$kendo-breadcrumb-link-focus-bg: null !default;
/// The text color of the focused Breadcrumb link.
/// @group breadcrumb
$kendo-breadcrumb-link-focus-text: $kendo-link-text !default;
/// The border color of the focused Breadcrumb link.
/// @group breadcrumb
$kendo-breadcrumb-link-focus-border: null !default;
/// The box shadow of the focused Breadcrumb link.
/// @group breadcrumb
$kendo-breadcrumb-link-focus-shadow: $kendo-focus-shadow !default;

/// The background color of the Breadcrumb root link.
/// @group breadcrumb
$kendo-breadcrumb-root-link-bg: null !default;
/// The text color of the Breadcrumb root link.
/// @group breadcrumb
$kendo-breadcrumb-root-link-text: null !default;
/// The border color of the Breadcrumb root link.
/// @group breadcrumb
$kendo-breadcrumb-root-link-border: null !default;

/// The background color of the hovered Breadcrumb root link.
/// @group breadcrumb
$kendo-breadcrumb-root-link-hover-bg: $kendo-base-bg !default;
/// The text color of the hovered Breadcrumb root link.
/// @group breadcrumb
$kendo-breadcrumb-root-link-hover-text: null !default;
/// The border color of the hovered Breadcrumb root link.
/// @group breadcrumb
$kendo-breadcrumb-root-link-hover-border: null !default;

/// The background color of the focused Breadcrumb root link.
/// @group breadcrumb
$kendo-breadcrumb-root-link-focus-bg: null !default;
/// The text color of the focused Breadcrumb root link.
/// @group breadcrumb
$kendo-breadcrumb-root-link-focus-text: null !default;
/// The border color of the focused Breadcrumb root link.
/// @group breadcrumb
$kendo-breadcrumb-root-link-focus-border: null !default;
/// The box shadow of the focused Breadcrumb root link.
/// @group breadcrumb
$kendo-breadcrumb-root-link-focus-shadow: $kendo-breadcrumb-link-focus-shadow !default;

/// The background color of the current Breadcrumb root link.
/// @group breadcrumb
$kendo-breadcrumb-current-item-bg: null !default;
/// The text color of the current Breadcrumb root link.
/// @group breadcrumb
$kendo-breadcrumb-current-item-text: null !default;
/// The border color of the current Breadcrumb root link.
/// @group breadcrumb
$kendo-breadcrumb-current-item-border: null !default;

/// The sizes map for the Breadcrumb.
/// @group breadcrumb
$kendo-breadcrumb-sizes: (
    sm: (
        link-padding-x: $kendo-breadcrumb-sm-link-padding-x,
        link-padding-y: $kendo-breadcrumb-sm-link-padding-y,
        icon-link-padding-x: $kendo-breadcrumb-sm-icon-link-padding-x,
        icon-link-padding-y: $kendo-breadcrumb-sm-icon-link-padding-y,
        font-size: $kendo-breadcrumb-sm-font-size,
        line-height: $kendo-breadcrumb-sm-line-height
    ),
    md: (
        link-padding-x: $kendo-breadcrumb-md-link-padding-x,
        link-padding-y: $kendo-breadcrumb-md-link-padding-y,
        icon-link-padding-x: $kendo-breadcrumb-md-icon-link-padding-x,
        icon-link-padding-y: $kendo-breadcrumb-md-icon-link-padding-y,
        font-size: $kendo-breadcrumb-md-font-size,
        line-height: $kendo-breadcrumb-md-line-height
    ),
    lg: (
        link-padding-x: $kendo-breadcrumb-lg-link-padding-x,
        link-padding-y: $kendo-breadcrumb-lg-link-padding-y,
        icon-link-padding-x: $kendo-breadcrumb-lg-icon-link-padding-x,
        icon-link-padding-y: $kendo-breadcrumb-lg-icon-link-padding-y,
        font-size: $kendo-breadcrumb-lg-font-size,
        line-height: $kendo-breadcrumb-lg-line-height
    )
) !default;

@forward "@progress/kendo-theme-core/scss/components/breadcrumb/_variables.scss" with (
    $kendo-breadcrumb-border-width: $kendo-breadcrumb-border-width,
    $kendo-breadcrumb-margin-x: $kendo-breadcrumb-margin-x,
    $kendo-breadcrumb-margin-y: $kendo-breadcrumb-margin-y,
    $kendo-breadcrumb-padding-x: $kendo-breadcrumb-padding-x,
    $kendo-breadcrumb-padding-y: $kendo-breadcrumb-padding-y,
    $kendo-breadcrumb-font-family: $kendo-breadcrumb-font-family,
    $kendo-breadcrumb-font-size: $kendo-breadcrumb-font-size,
    $kendo-breadcrumb-sm-font-size: $kendo-breadcrumb-sm-font-size,
    $kendo-breadcrumb-md-font-size: $kendo-breadcrumb-md-font-size,
    $kendo-breadcrumb-lg-font-size: $kendo-breadcrumb-lg-font-size,
    $kendo-breadcrumb-line-height: $kendo-breadcrumb-line-height,
    $kendo-breadcrumb-sm-line-height: $kendo-breadcrumb-sm-line-height,
    $kendo-breadcrumb-md-line-height: $kendo-breadcrumb-md-line-height,
    $kendo-breadcrumb-lg-line-height: $kendo-breadcrumb-lg-line-height,
    $kendo-breadcrumb-bg: $kendo-breadcrumb-bg,
    $kendo-breadcrumb-text: $kendo-breadcrumb-text,
    $kendo-breadcrumb-border: $kendo-breadcrumb-border,
    $kendo-breadcrumb-focus-shadow: $kendo-breadcrumb-focus-shadow,
    $kendo-breadcrumb-link-padding-x: $kendo-breadcrumb-link-padding-x,
    $kendo-breadcrumb-sm-link-padding-x: $kendo-breadcrumb-sm-link-padding-x,
    $kendo-breadcrumb-md-link-padding-x: $kendo-breadcrumb-md-link-padding-x,
    $kendo-breadcrumb-lg-link-padding-x: $kendo-breadcrumb-lg-link-padding-x,
    $kendo-breadcrumb-link-padding-y: $kendo-breadcrumb-link-padding-y,
    $kendo-breadcrumb-sm-link-padding-y: $kendo-breadcrumb-sm-link-padding-y,
    $kendo-breadcrumb-md-link-padding-y: $kendo-breadcrumb-md-link-padding-y,
    $kendo-breadcrumb-lg-link-padding-y: $kendo-breadcrumb-lg-link-padding-y,
    $kendo-breadcrumb-link-border-radius: $kendo-breadcrumb-link-border-radius,
    $kendo-breadcrumb-icon-link-padding-y: $kendo-breadcrumb-icon-link-padding-y,
    $kendo-breadcrumb-sm-icon-link-padding-y: $kendo-breadcrumb-sm-icon-link-padding-y,
    $kendo-breadcrumb-md-icon-link-padding-y: $kendo-breadcrumb-md-icon-link-padding-y,
    $kendo-breadcrumb-lg-icon-link-padding-y: $kendo-breadcrumb-lg-icon-link-padding-y,
    $kendo-breadcrumb-icon-link-padding-x: $kendo-breadcrumb-icon-link-padding-x,
    $kendo-breadcrumb-sm-icon-link-padding-x: $kendo-breadcrumb-sm-icon-link-padding-x,
    $kendo-breadcrumb-md-icon-link-padding-x: $kendo-breadcrumb-md-icon-link-padding-x,
    $kendo-breadcrumb-lg-icon-link-padding-x: $kendo-breadcrumb-lg-icon-link-padding-x,
    $kendo-breadcrumb-link-initial-text: $kendo-breadcrumb-link-initial-text,
    $kendo-breadcrumb-link-icon-spacing: $kendo-breadcrumb-link-icon-spacing,
    $kendo-breadcrumb-link-bg: $kendo-breadcrumb-link-bg,
    $kendo-breadcrumb-link-text: $kendo-breadcrumb-link-text,
    $kendo-breadcrumb-link-border: $kendo-breadcrumb-link-border,
    $kendo-breadcrumb-link-hover-bg: $kendo-breadcrumb-link-hover-bg,
    $kendo-breadcrumb-link-hover-text: $kendo-breadcrumb-link-hover-text,
    $kendo-breadcrumb-link-hover-border: $kendo-breadcrumb-link-hover-border,
    $kendo-breadcrumb-link-focus-bg: $kendo-breadcrumb-link-focus-bg,
    $kendo-breadcrumb-link-focus-text: $kendo-breadcrumb-link-focus-text,
    $kendo-breadcrumb-link-focus-border: $kendo-breadcrumb-link-focus-border,
    $kendo-breadcrumb-link-focus-shadow: $kendo-breadcrumb-link-focus-shadow,
    $kendo-breadcrumb-root-link-bg: $kendo-breadcrumb-root-link-bg,
    $kendo-breadcrumb-root-link-text: $kendo-breadcrumb-root-link-text,
    $kendo-breadcrumb-root-link-border: $kendo-breadcrumb-root-link-border,
    $kendo-breadcrumb-root-link-hover-bg: $kendo-breadcrumb-root-link-hover-bg,
    $kendo-breadcrumb-root-link-hover-text: $kendo-breadcrumb-root-link-hover-text,
    $kendo-breadcrumb-root-link-hover-border: $kendo-breadcrumb-root-link-hover-border,
    $kendo-breadcrumb-root-link-focus-bg: $kendo-breadcrumb-root-link-focus-bg,
    $kendo-breadcrumb-root-link-focus-text: $kendo-breadcrumb-root-link-focus-text,
    $kendo-breadcrumb-root-link-focus-border: $kendo-breadcrumb-root-link-focus-border,
    $kendo-breadcrumb-root-link-focus-shadow: $kendo-breadcrumb-root-link-focus-shadow,
    $kendo-breadcrumb-current-item-bg: $kendo-breadcrumb-current-item-bg,
    $kendo-breadcrumb-current-item-text: $kendo-breadcrumb-current-item-text,
    $kendo-breadcrumb-current-item-border: $kendo-breadcrumb-current-item-border,
    $kendo-breadcrumb-sizes: $kendo-breadcrumb-sizes
);
