@use "../core/_index.scss" as *;
@use "../core/functions/index.import.scss" as *;

// Slider
/// The default size of the Slider.
/// @group slider
$kendo-slider-size: 200px !default;
/// The default size of the Slider tick.
/// @group slider
$kendo-slider-tick-size: null !default;
/// The default size of the Slider's track wrap.
/// @group slider
$kendo-slider-alt-size: 30px !default;

/// The font family of the Slider.
/// @group slider
$kendo-slider-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the Slider.
/// @group slider
$kendo-slider-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the Slider.
/// @group slider
$kendo-slider-line-height: var( --kendo-line-height, normal ) !default;

/// The offset of the Slider Buttons.
/// @group slider
$kendo-slider-button-offset: 10px !default;
/// The size of the Slider Buttons.
/// @group slider
$kendo-slider-button-size: ($kendo-slider-alt-size - 2) !default;
/// The spacing of the Slider Buttons.
/// @group slider
$kendo-slider-button-spacing: ($kendo-slider-button-size + $kendo-slider-button-offset) !default;
/// The shadow of the focused Slider Buttons.
/// @group slider
$kendo-slider-button-focus-shadow: null !default;

/// The thickness of the Slider track.
/// @group slider
$kendo-slider-track-thickness: 4px !default;
/// The size of the Slider drag handle.
/// @group slider
$kendo-slider-draghandle-size: 14px !default;
/// The border width of the Slider drag handle.
/// @group slider
$kendo-slider-draghandle-border-width: 1px !default;
/// The transition scale of the active Slider drag handle.
/// @group slider
$kendo-slider-draghandle-active-scale: 1 !default;
/// The size of the active Slider drag handle.
/// @group slider
$kendo-slider-draghandle-active-size: null !default;

/// The background color of the Slider drag handle.
/// @group slider
$kendo-slider-draghandle-bg: $kendo-color-primary !default;
/// The text color of the Slider drag handle.
/// @group slider
$kendo-slider-draghandle-text: if($kendo-enable-color-system, k-color( on-primary ), k-contrast-legacy( $kendo-color-primary )) !default;
/// The border color of the Slider drag handle.
/// @group slider
$kendo-slider-draghandle-border: $kendo-color-primary !default;
/// The gradient of the Slider drag handle.
/// @group slider
$kendo-slider-draghandle-gradient: null !default;

/// The background color of the hovered Slider drag handle.
/// @group slider
$kendo-slider-draghandle-hover-bg: if($kendo-enable-color-system, k-color( primary-hover ), k-try-shade( $kendo-color-primary , .5 )) !default;
/// The text color of the hovered Slider drag handle.
/// @group slider
$kendo-slider-draghandle-hover-text: null !default;
/// The border color of the hovered Slider drag handle.
/// @group slider
$kendo-slider-draghandle-hover-border: $kendo-color-primary !default;
/// The gradient of the hovered Slider drag handle.
/// @group slider
$kendo-slider-draghandle-hover-gradient: null !default;

/// The background color of the active Slider drag handle.
/// @group slider
$kendo-slider-draghandle-pressed-bg: if($kendo-enable-color-system, k-color( primary-active ), k-try-shade( $kendo-color-primary , 1.5 )) !default;
/// The text color of the active Slider drag handle.
/// @group slider
$kendo-slider-draghandle-pressed-text: null !default;
/// The border color of the active Slider drag handle.
/// @group slider
$kendo-slider-draghandle-pressed-border: if($kendo-enable-color-system, k-color( primary-active ), k-try-shade( $kendo-color-primary , 1.5 )) !default;
/// The gradient of the active Slider drag handle.
/// @group slider
$kendo-slider-draghandle-pressed-gradient: null !default;

/// The shadow of the focused Slider drag handle.
/// @group slider
$kendo-slider-draghandle-focus-shadow: 0 0 0 2px if($kendo-enable-color-system, color-mix(in srgb, k-color( primary ) 30%, transparent), rgba( $kendo-color-primary , .3 )) !default;

/// The transition speed of the Slider.
/// @group slider
$kendo-slider-transition-speed: .3s !default;
/// The transition function of the Slider.
/// @group slider
$kendo-slider-transition-function: ease-out !default;
/// The transition speed of the Slider drag handle.
/// @group slider
$kendo-slider-draghandle-transition-speed: .4s !default;
/// The transition function of the Slider drag handle.
/// @group slider
$kendo-slider-draghandle-transition-function: cubic-bezier(.25, .8, .25, 1) !default;

/// The background color of the Slider track.
/// @group slider
$kendo-slider-track-bg: if($kendo-enable-color-system, k-color( base-emphasis ), k-try-shade( $kendo-component-bg, 1 )) !default;
/// The background color of the Slider's track selection.
/// @group slider
$kendo-slider-selection-bg: $kendo-color-primary !default;
/// @group slider
/// The opacity of the disabled Slider.
$kendo-slider-disabled-opacity: null !default;

/// The background image of the horizontal Slider tick.
/// @group slider
$kendo-slider-tick-horizontal-image: "data:image/gif;base64,R0lGODlhAQC0AIABALi4uAAAACH5BAEAAAEALAAAAAABALQAAAIWjIGJxqzazlux2ovlzND2rAHgSIZWAQA7" !default;
/// The background image of the vertical Slider tick.
/// @group slider
$kendo-slider-tick-vertical-image: "data:image/gif;base64,R0lGODlhtAABAIABALi4uAAAACH5BAEAAAEALAAAAAC0AAEAAAIWjIGJxqzazlux2ovlzND2rAHgSIZWAQA7" !default;

@forward "@progress/kendo-theme-core/scss/components/slider/_variables.scss" with (
    $kendo-slider-size: $kendo-slider-size,
    $kendo-slider-tick-size: $kendo-slider-tick-size,
    $kendo-slider-alt-size: $kendo-slider-alt-size,
    $kendo-slider-font-family: $kendo-slider-font-family,
    $kendo-slider-font-size: $kendo-slider-font-size,
    $kendo-slider-line-height: $kendo-slider-line-height,
    $kendo-slider-button-offset: $kendo-slider-button-offset,
    $kendo-slider-button-size: $kendo-slider-button-size,
    $kendo-slider-button-spacing: $kendo-slider-button-spacing,
    $kendo-slider-button-focus-shadow: $kendo-slider-button-focus-shadow,
    $kendo-slider-track-thickness: $kendo-slider-track-thickness,
    $kendo-slider-draghandle-size: $kendo-slider-draghandle-size,
    $kendo-slider-draghandle-border-width: $kendo-slider-draghandle-border-width,
    $kendo-slider-draghandle-active-scale: $kendo-slider-draghandle-active-scale,
    $kendo-slider-draghandle-active-size: $kendo-slider-draghandle-active-size,
    $kendo-slider-draghandle-bg: $kendo-slider-draghandle-bg,
    $kendo-slider-draghandle-text: $kendo-slider-draghandle-text,
    $kendo-slider-draghandle-border: $kendo-slider-draghandle-border,
    $kendo-slider-draghandle-gradient: $kendo-slider-draghandle-gradient,
    $kendo-slider-draghandle-hover-bg: $kendo-slider-draghandle-hover-bg,
    $kendo-slider-draghandle-hover-text: $kendo-slider-draghandle-hover-text,
    $kendo-slider-draghandle-hover-border: $kendo-slider-draghandle-hover-border,
    $kendo-slider-draghandle-hover-gradient: $kendo-slider-draghandle-hover-gradient,
    $kendo-slider-draghandle-pressed-bg: $kendo-slider-draghandle-pressed-bg,
    $kendo-slider-draghandle-pressed-text: $kendo-slider-draghandle-pressed-text,
    $kendo-slider-draghandle-pressed-border: $kendo-slider-draghandle-pressed-border,
    $kendo-slider-draghandle-pressed-gradient: $kendo-slider-draghandle-pressed-gradient,
    $kendo-slider-draghandle-focus-shadow: $kendo-slider-draghandle-focus-shadow,
    $kendo-slider-transition-speed: $kendo-slider-transition-speed,
    $kendo-slider-transition-function: $kendo-slider-transition-function,
    $kendo-slider-draghandle-transition-speed: $kendo-slider-draghandle-transition-speed,
    $kendo-slider-draghandle-transition-function: $kendo-slider-draghandle-transition-function,
    $kendo-slider-track-bg: $kendo-slider-track-bg,
    $kendo-slider-selection-bg: $kendo-slider-selection-bg,
    $kendo-slider-disabled-opacity: $kendo-slider-disabled-opacity,
    $kendo-slider-tick-horizontal-image: $kendo-slider-tick-horizontal-image,
    $kendo-slider-tick-vertical-image: $kendo-slider-tick-vertical-image
);
