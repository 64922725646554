/// This is equivalent to `transform: scaleX( -1 );`.
/// @example transform: scaleX( -1 );
/// @name .k-flip-h
/// @group transform
/// @contextType css

/// This is equivalent to `transform: scaleY( -1 );`.
/// @example transform: scaleY( -1 );
/// @name .k-flip-v
/// @group transform
/// @contextType css

// TODO: consider globally horizontal vs h vs x

@use "sass:map";
@use "../_globals.scss" as *;

// Register
@use "@progress/kendo-theme-core/scss/mixins/import-once.scss" as *;

@mixin kendo-utils--transform--flip() {
    @include import-once("utils-transform-flip") {

        // Flip utility classes
        $kendo-utils-flip: map.get( $kendo-utils, "flip" ) !default;
        @if $kendo-utils-flip {
            .#{$kendo-prefix}flip-h { transform: scaleX( -1 ); }
            .\!#{$kendo-prefix}flip-h { transform: scaleX( -1 ) !important; } // stylelint-disable-line declaration-no-important
            .#{$kendo-prefix}flip-v { transform: scaleY( -1 ); }
            .\!#{$kendo-prefix}flip-v { transform: scaleY( -1 ) !important; } // stylelint-disable-line declaration-no-important
            .#{$kendo-prefix}flip-h.#{$kendo-prefix}flip-v { transform: scale( -1, -1 ); }
            .\!#{$kendo-prefix}flip-h.\!#{$kendo-prefix}flip-v { transform: scale( -1, -1 ) !important; } // stylelint-disable-line declaration-no-important

            // Legacy aliases
            .#{$kendo-prefix}flip-x { @extend .#{$kendo-prefix}flip-h !optional; }
            .\!#{$kendo-prefix}flip-x { @extend .\!#{$kendo-prefix}flip-h !optional; }
            .#{$kendo-prefix}flip-y { @extend .#{$kendo-prefix}flip-v !optional; }
            .\!#{$kendo-prefix}flip-y { @extend .\!#{$kendo-prefix}flip-v !optional; }
        }

    }
}
