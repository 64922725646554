@use "sass:map";
@use "../../color-system/_constants.scss" as *;
@use "../../mixins/index.import.scss" as *;
@use "../icons/_variables.scss" as *;
@use "./_variables.scss" as *;

@mixin kendo-tabstrip--layout-base() {

    // Tabstrip wrapper
    .k-tabstrip-wrapper {
        padding-block: $kendo-tabstrip-wrapper-padding-y;
        padding-inline: $kendo-tabstrip-wrapper-padding-x;
        box-sizing: border-box;
        border-width: $kendo-tabstrip-wrapper-border-width;
        border-style: solid;
        display: flex;
        flex-direction: column;
        position: relative;
    }


    // Tabstrip
    .k-tabstrip {
        border-width: 0;
        border-color: transparent;
        box-sizing: border-box;
        outline: 0;
        font-family: $kendo-tabstrip-font-family;
        font-size: $kendo-tabstrip-font-size;
        line-height: $kendo-tabstrip-line-height;
        color: inherit;
        background-color: transparent;
        display: flex;
        flex-flow: column nowrap;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $kendo-color-rgba-transparent;
    }
    .k-tabstrip-wrapper > .k-tabstrip {
        flex: 1 1 auto;
    }


    // Tabstrip items wrapper
    .k-tabstrip-items-wrapper {
        box-sizing: border-box;
        border-width: 0;
        border-style: solid;
        border-color: inherit;
        position: relative;
        flex-shrink: 0;
    }


    // Tabstrip items
    .k-tabstrip-items {
        box-sizing: border-box;
        outline: 0;
        display: flex;
        flex-direction: inherit;
        flex-wrap: wrap;
        flex: 1 1 auto;
        gap: $kendo-tabstrip-item-gap;

        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }

        .k-item {
            margin: 0;
            padding: 0;
            border: $kendo-tabstrip-item-border-width solid transparent;
            position: relative;
            flex-shrink: 0;
            display: flex;
            flex-direction: row;
            align-items: stretch;
            justify-items: stretch;
            outline: 0;

            // Active state
            &:active,
            &.k-active,
            &.k-selected {
                font-weight: $kendo-tabstrip-item-selected-font-weight;
            }
        }

        .k-item-actions {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
        }

        .k-link {
            padding-block: $kendo-tabstrip-item-padding-y;
            padding-inline: $kendo-tabstrip-item-padding-x;
            color: inherit;
            cursor: pointer;
            display: inline-flex;
            vertical-align: middle;
            flex: 1 1 auto;
            flex-direction: row;
            align-content: center;
            align-items: center;
            gap: $kendo-icon-spacing;
        }


        // Disabled state
        .k-item.k-disabled,
        .k-item[disabled] {
            opacity: $kendo-tabstrip-item-disabled-opacity;
            filter: $kendo-tabstrip-item-disabled-filter;
        }
    }


    // Tabstrip content
    .k-tabstrip-content,
    .k-tabstrip > .k-content {
        margin: 0 !important; // stylelint-disable-line declaration-no-important
        padding-block: $kendo-tabstrip-content-padding-y;
        padding-inline: $kendo-tabstrip-content-padding-x;
        box-sizing: border-box;
        border-width: $kendo-tabstrip-content-border-width;
        border-style: solid;
        border-color: inherit;
        display: none;
        overflow: auto;
        flex: 1 1 auto;
        position: relative;

        &.k-active {
            display: block;
        }

        &:focus,
        &.k-focus {
            outline-width: 1px;
            outline-style: dotted;
            outline-offset: -1px;
        }
    }


    // Loading indicator
    .k-tabstrip-items .k-loading {
        width: 20%;
        height: 0;
        border: 0;
        border-top: 1px solid transparent;
        border-color: inherit;
        background: none;
        position: absolute;
        top: 0;
        left: 0;
        transition: width .2s linear;

        // TODO: a better name
        display: none;

        &.k-complete {
            width: 100%;
            border-top-width: 0;
        }
    }


    // Scrolling
    .k-tabstrip-scrollable {
        > .k-tabstrip-items-wrapper {
            > .k-tabstrip-items {
                flex-wrap: nowrap;
                white-space: nowrap;
                overflow: hidden;

                &.k-tabstrip-items-scroll {
                    scrollbar-width: none;

                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
        }

        &.k-tabstrip-top,
        &.k-tabstrip-bottom {
            .k-tabstrip-items.k-tabstrip-items-scroll {
                overflow-x: auto;
            }
        }

        &.k-tabstrip-left,
        &.k-tabstrip-right {
            .k-tabstrip-items.k-tabstrip-items-scroll {
                overflow-y: auto;
            }
        }
    }

    .k-tabstrip-scrollable-overlay {
        .k-tabstrip-items-wrapper {
            &::before,
            &::after {
                content: '';
                position: absolute;
                z-index: 3;
            }
        }

        &.k-tabstrip-top,
        &.k-tabstrip-bottom {
            .k-tabstrip-items-wrapper {
                &::before,
                &::after {
                    height: 100%;
                    aspect-ratio: 1;
                }
                &::before {
                    inset-inline-start: 0;
                }

                &::after {
                    inset-inline-end: 0;
                }
            }
        }

        &.k-tabstrip-left,
        &.k-tabstrip-right {
            .k-tabstrip-items-wrapper {
                &::before,
                &::after {
                    width: 100%;
                }
                &::before {
                    inset-block-start: 0;
                }

                &::after {
                    inset-block-end: 0;
                }
            }
        }

        &.k-tabstrip-scrollable-start {
            .k-tabstrip-items-wrapper {
                &::before {
                    display: none;
                }
            }
        }

        &.k-tabstrip-scrollable-end {
            .k-tabstrip-items-wrapper {
                &::after {
                    display: none;
                }
            }
        }
    }


    // Tabstrip orientation
    .k-tabstrip-horizontal {
        flex-direction: row;
    }
    .k-tabstrip-vertical {
        flex-direction: column;
    }


    // Tabstrip position
    .k-tabstrip-top {
        > .k-tabstrip-items-wrapper {
            @extend .k-tabstrip-horizontal !optional;
            border-bottom-width: $kendo-tabstrip-border-width;

            .k-item {
                @include border-top-radius( $kendo-tabstrip-item-border-radius );
                border-bottom-width: 0;

                .k-link {
                    justify-content: center;
                }
            }
            .k-item:active,
            .k-item.k-active {
                margin-bottom: if( $kendo-tabstrip-border-width, -$kendo-tabstrip-border-width, null );
                border-bottom-width: $kendo-tabstrip-border-width;
                border-bottom-color: transparent !important; // stylelint-disable-line declaration-no-important
            }
        }

        > .k-content,
        > .k-tabstrip-content {
            @include border-bottom-radius( $kendo-tabstrip-item-border-radius );
            border-top-width: 0 !important; // stylelint-disable-line declaration-no-important
        }
    }
    .k-tabstrip-bottom {
        > .k-tabstrip-items-wrapper {
            @extend .k-tabstrip-horizontal !optional;
            border-top-width: $kendo-tabstrip-border-width;

            .k-item {
                @include border-bottom-radius( $kendo-tabstrip-item-border-radius );
                border-top-width: 0;

                .k-link {
                    justify-content: center;
                }
            }
            .k-item:active,
            .k-item.k-active {
                margin-top: if( $kendo-tabstrip-border-width, -$kendo-tabstrip-border-width, null );
                border-top-width: $kendo-tabstrip-border-width;
                border-top-color: transparent !important; // stylelint-disable-line declaration-no-important
            }
        }

        > .k-content,
        > .k-tabstrip-content {
            @include border-top-radius( $kendo-tabstrip-item-border-radius );
            border-bottom-width: 0 !important; // stylelint-disable-line declaration-no-important
        }
    }
    .k-tabstrip-left {
        flex-direction: row;

        > .k-tabstrip-items-wrapper {
            @extend .k-tabstrip-vertical !optional;
            border-right-width: $kendo-tabstrip-border-width;

            .k-item {
                @include border-left-radius( $kendo-tabstrip-item-border-radius );
                border-right-width: 0;
            }
            .k-item:active,
            .k-item.k-active {
                margin-right: if( $kendo-tabstrip-border-width, -$kendo-tabstrip-border-width, null );
                border-right-width: $kendo-tabstrip-border-width;
                border-right-color: transparent !important; // stylelint-disable-line declaration-no-important
            }
        }

        > .k-content,
        > .k-tabstrip-content {
            @include border-right-radius( $kendo-tabstrip-item-border-radius );
            border-left-width: 0 !important; // stylelint-disable-line declaration-no-important
        }

    }
    .k-tabstrip-right {
        flex-direction: row-reverse;

        > .k-tabstrip-items-wrapper {
            @extend .k-tabstrip-vertical !optional;
            border-left-width: $kendo-tabstrip-border-width;

            .k-item {
                @include border-right-radius( $kendo-tabstrip-item-border-radius );
                border-left-width: 0;
            }
            .k-item:active,
            .k-item.k-active {
                margin-left: if( $kendo-tabstrip-border-width, -$kendo-tabstrip-border-width, null );
                border-left-width: $kendo-tabstrip-border-width;
                border-left-color: transparent !important; // stylelint-disable-line declaration-no-important
            }
        }

        > .k-content,
        > .k-tabstrip-content {
            @include border-left-radius( $kendo-tabstrip-item-border-radius );
            border-right-width: 0;
        }
    }


    // Tabstrip align
    .k-tabstrip-items-start {
        justify-content: flex-start;
    }
    .k-tabstrip-items-center {
        justify-content: center;
    }
    .k-tabstrip-items-end {
        justify-content: flex-end;
    }
    .k-tabstrip-items-justify {
        justify-content: space-between;
    }
    .k-tabstrip-items-stretched > * {
        flex: 1 0 0;
    }


    // Selected indicator
    @if ($kendo-tabstrip-indicator-size) {

        .k-tabstrip-items-wrapper {

            .k-item::after {
                content: "";
                border-width: 0;
                border-style: solid;
                display: none;
                position: absolute;
                z-index: 2;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                pointer-events: none;
            }
            .k-item.k-active::after {
                display: block;
            }

            .k-item > .k-link {
                z-index: 1;
            }
        }


        // Variants
        .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item::after {
            border-bottom-width: $kendo-tabstrip-indicator-size;
        }
        .k-tabstrip-bottom > .k-tabstrip-items-wrapper .k-item::after {
            border-top-width: $kendo-tabstrip-indicator-size;
        }
        .k-tabstrip-left > .k-tabstrip-items-wrapper .k-item::after {
            border-right-width: $kendo-tabstrip-indicator-size;
        }
        .k-tabstrip-right > .k-tabstrip-items-wrapper .k-item::after {
            border-left-width: $kendo-tabstrip-indicator-size;
        }


        // Hide tabstrip indicator when dragging
        .k-tabstrip-items-wrapper .k-item.k-tabstrip-dragging {
            &::after {
                display: none !important; // stylelint-disable-line declaration-no-important
            }
        }

    }


    // RTL
    .k-rtl .k-tabstrip,
    .k-tabstrip[dir="rtl"] {

        &.k-tabstrip-left,
        &.k-tabstrip-right {
            > .k-tabstrip-items-wrapper {
                order: 1;
            }

            > .k-content { order: 0; }
            > .k-tabstrip-content { order: 0; }
        }

    }

     // TabStrip sizes
     @each $size, $size-props in $kendo-tabstrip-sizes {
        $_font-size: map.get( $size-props, font-size );
        $_line-height: map.get( $size-props, line-height );
        $_item-padding-x: map.get( $size-props, item-padding-x );
        $_item-padding-y: map.get( $size-props, item-padding-y );

        .k-tabstrip-#{$size} {
            .k-tabstrip-items .k-link {
                font-size: $_font-size;
                line-height: $_line-height;
                padding-block: $_item-padding-y;
                padding-inline: $_item-padding-x;
            }

            :is(&.k-tabstrip-left, &.k-tabstrip-right):is(.k-tabstrip-scrollable-overlay) :is(.k-tabstrip-items-wrapper){
                &::before,
                &::after {
                    height: calc( ($_line-height * 1em) + ($kendo-tabstrip-border-width * 2) + ($_item-padding-y * 2) );
                }
            }
        }
    }

}


@mixin kendo-tabstrip--layout() {
    @include kendo-tabstrip--layout-base();
}
