@use "../../mixins/index.import.scss" as *;
@use "./_variables.scss" as *;

@mixin kendo-split-button--theme-base() {

    // Split button
    .k-split-button:focus,
    .k-split-button.k-focus {
        @include focus-indicator( $kendo-split-button-focus-shadow );
    }

}


@mixin kendo-split-button--theme() {
    @include kendo-split-button--theme-base();
}
