@use "bootstrap/scss/bootstrap.scss";
@use "webfontskit.scss";

@use "@progress/kendo-theme-default/dist/all.scss";

html {
  scroll-padding-top: 75px; // allows to use scroll into view with a fixed top bar
}

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  @media (min-width: 1500px) {
    max-width: 1460px;
  }
}

:root {
  --color-brand-primary-default: #2a7ab0;
  --color-brand-primary-default-opacity-004: rgba(42, 122, 176, 0.04);
  --color-brand-primary-default-opacity-025: rgba(42, 122, 176, 0.25);
  --color-brand-primary-hover: #fff;
  --color-brand-primary-hover-dark: #1c5073;
  --color-brand-primary-active: #7cb7df;
  --color-brand-primary-disabled: #f6f6f6;
  --color-brand-primary-disabled-dark: #aaa;
  --color-brand-primary-text: #ffffff;
  --color-brand-primary-text-hover: var(--color-brand-primary-default);
  --color-brand-primary-text-disabled: #656565;
  --color-brand-primary-text-disabled-dark: var(--color-brand-primary-disabled);
  --color-brand-secondary-default: #656565;
  --color-brand-secondary-hover: #fff;
  --color-brand-secondary-active: #333333;
  --color-brand-secondary-disabled: #f6f6f6;
  --color-brand-secondary-text: #fff;
  --color-brand-secondary-text-hover: var(--color-brand-secondary-default);
  --color-brand-secondary-text-disabled: #656565;
  --color-brand-tertiary-default: #f6f6f6;
  --color-brand-tertiary-hover: #fff;
  --color-brand-tertiary-active: #d6d6d6;
  --color-brand-tertiary-disabled: #f6f6f6;
  --color-brand-tertiary-text: #656565;
  --color-brand-tertiary-text-hover: var(--color-brand-tertiary-text);
  --color-brand-tertiary-text-disabled: #656565;
  --color-bg-default: #f6f6f6;
  --color-bg-darker: #ebebeb;
  --color-text-gray: #a8a8a8;
  --color-button-bg-primary-default: var(--color-brand-primary-default);
  --color-button-bg-primary-hover: var(--color-brand-primary-hover);
  --color-button-bg-primary-active: var(--color-brand-primary-default);
  --color-button-bg-primary-disabled: var(--color-brand-primary-disabled);
  --color-button-bg-secondary-default: var(--color-brand-secondary-default);
  --color-button-bg-secondary-hover: var(--color-brand-secondary-hover);
  --color-button-bg-secondary-active: var(--color-brand-secondary-default);
  --color-button-bg-secondary-disabled: var(--color-brand-secondary-disabled);
  --color-button-bg-tertiary-default: var(--color-brand-tertiary-default);
  --color-button-bg-tertiary-hover: var(--color-brand-tertiary-hover);
  --color-button-bg-tertiary-active: var(--color-button-bg-secondary-active);
  --color-button-bg-tertiary-disabled: var(--color-brand-tertiary-disabled);
  --color-button-text-primary-default: var(--color-brand-primary-text);
  --color-button-text-primary-hover: var(--color-brand-primary-text-hover);
  --color-button-text-primary-active: var(--color-brand-primary-text);
  --color-button-text-primary-disabled: var(--color-brand-primary-text-disabled);
  --color-button-text-secondary-default: var(--color-brand-secondary-text);
  --color-button-text-secondary-hover: var(--color-brand-secondary-text-hover);
  --color-button-text-secondary-active: var(--color-brand-secondary-text);
  --color-button-text-secondary-disabled: var(--color-brand-secondary-text-disabled);
  --color-button-text-tertiary-default: var(--color-brand-tertiary-text);
  --color-button-text-tertiary-hover: var(--color-brand-tertiary-text-hover);
  --color-button-text-tertiary-active: var(--color-button-text-secondary-active);
  --color-button-text-tertiary-disabled: var(--color-brand-tertiary-text-disabled);
  --color-button-border-primary-default: transparent;
  --color-button-border-primary-hover: var(--color-button-text-primary-hover);
  --color-button-border-primary-active: var(--color-button-text-primary-active);
  --color-button-border-secondary-default: transparent;
  --color-button-border-secondary-hover: var(--color-button-text-secondary-hover);
  --color-button-border-secondary-active: var(--color-button-text-secondary-active);
  --color-button-border-tertiary-default: #eef0f2;
  --color-button-border-tertiary-hover: var(--color-button-text-tertiary-hover);
  --color-button-border-tertiary-active: var(--color-button-border-secondary-active);
  --logo-background-color: var(--color-brand-primary-default);
  --kendo-color-primary: #2A7AB0;
  --kendo-color-primary-active: rgb(36.96, 107.36, 154.88);
  --kendo-color-primary-subtle: rgb(212.4, 228.4, 239.2);
  --kendo-color-primary-subtle-hover: rgb(180.45, 208.45, 227.35); 
  --kendo-color-primary-subtle-active: rgb(148.5, 188.5, 215.5); 
  --kendo-color-primary-hover: #1c5073; 
  --kendo-color-primary-active: rgb(36.96, 107.36, 154.88);
  --kendo-color-primary-emphasis: rgb(95.25, 155.25, 195.75);
  --kendo-color-primary-on-subtle: rgb(14.7, 42.7, 61.6);
  --kendo-color-primary-on-surface: rgb(31.5, 91.5, 132);
}

body {
  font-family: "Noto Sans", "Segoe UI", Arial, Helvetica, sans-serif;
}

.no-scroll {
  overflow: hidden;
}

.mh-20 {
  max-height: 20% !important;
}

.mh-80 {
  max-height: 80% !important;
}

.h-20 {
  height: 20% !important;
}

.h-80 {
  height: 80% !important;
}

.Logo-background {
  fill: var(--logo-background-color);
}

.k-grid-md .k-edit-cell,
.k-grid-md .k-command-cell,
.k-grid-md .k-grid-edit-row td,
.k-grid-md .k-grid-edit-row .k-table-td {
  padding: 8px 12px !important;
}

.k-button-solid-primary {
  transition: 0.3s ease all;
  color: var(--color-button-text-primary-default);
  background-color: var(--color-button-bg-primary-default);
  border: 3px solid var(--color-button-border-primary-default);
  border-radius: 4px;
  cursor: pointer;
}

.k-button-solid-primary:hover {
  color: var(--color-button-text-primary-hover);
  background-color: var(--color-button-bg-primary-hover);
  border-color: var(--color-button-border-primary-hover);
  cursor: pointer;
}

.k-button-solid-primary:active {
  color: var(--color-button-text-primary-active);
  background-color: var(--color-button-bg-primary-active);
  border-color: var(--color-button-border-primary-active);
}

.k-button-secondary {
  transition: 0.3s ease all;
  color: var(--color-button-text-secondary-default);
  background-color: var(--color-brand-secondary-default);
  border: 2px solid var(--color-button-border-secondary-default);
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
}

.k-button-secondary:hover {
  color: var(--color-button-text-secondary-hover);
  background-color: var(--color-brand-secondary-hover);
  border-color: var(--color-button-border-secondary-hover);
  cursor: pointer;
}

.k-button-secondary:active {
  color: var(--color-button-text-secondary-active);
  background-color: var(--color-brand-secondary-active);
  border-color: var(--color-button-border-secondary-active);
}

.k-button-secondary:disabled {
  color: var(--color-button-text-secondary-disabled);
  background-color: var(--color-brand-secondary-disabled);
  border-color: var(--color-button-border-secondary-default);
}

.labelGrey {
  height: 20px;
  color: #656565;
  font-family: "Noto Sans", "Segoe UI", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.labelBlack {
  height: 20px;
  color: #000000;
  font-family: "Noto Sans", "Segoe UI", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.headerBig {
  height: 37.24px;
  color: #000000;
  font-family: "Foundry Gridnik";
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 37.24px;
}

.text-label-small {
  height: 20px;
  color: #656565;
  font-family: "Noto Sans", "Segoe UI", Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.searchShared {
  width: 100%;
  color: #000000;
  font-family: "Noto Sans", "Segoe UI", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.body-content {
  margin-top: 5em;
}

.k-grid {
  border: none;
  max-height: 93vh;
  overflow: auto;

  .k-grid-header-wrap {
    border: none;
  }

  .k-grid-pager {
    background: none;
    border: none;
  }

  .k-pager-numbers .k-link {
    height: 30px !important;
    font-family: "Noto Sans", "Segoe UI", Arial, Helvetica, sans-serif;
    font-size: 14px;
    text-align: center;
    color: black;
  }

  .k-pager-numbers .k-link.k-state-selected {
    background-color: var(--color-brand-primary-default) !important;
    color: white;
  }

  .k-pager-info {
    font-family: "Noto Sans", "Segoe UI", Arial, Helvetica, sans-serif;
    font-size: 14px;
  }
}

.k-grid-content {
  overflow-y: auto;
}

.k-grid-content tr {
  background-color: var(--color-bg-default);
}

.k-grid-content .k-alt {
  background-color: #ffffff !important;
}

.k-grid-header {
  padding-right: 0 !important;
  background-color: #ffffff;
  border: none;
  vertical-align: text-bottom !important;
  z-index: 10;
  position: sticky;

  .k-header {
    border: 0;
    color: #656565;
    font-family: "Noto Sans Mono", "Segoe UI Mono", "Courier New", Courier,
      monospace;
    font-size: 11px;
    letter-spacing: 0;
    // padding-right: 0px;
    overflow: visible !important;
    white-space: normal !important;
    word-spacing: 1000px;
    // > .k-link{
    //   height: 100% !important;

    //   span{
    //     height: 15px;
    //     width: 15px;
    //   }
    // }
  }

  .k-i-sort-asc-sm:before {
    content: "\e013";
    color: black;
    //   position: absolute;
    //   bottom: 2px;
    //   right: 20px;
    //    height:8px;

    font-weight: bold;
  }

  .k-i-sort-desc-sm:before {
    content: "\e015";
    color: black;
    //  position: absolute;
    //   bottom: 2px;
    //  right: 20px;
    //   height:8px;

    font-weight: bold;
  }
}

.k-grid td {
  border: none;
  height: 34px;
  color: #000000;
  font-family: "Noto Sans", "Segoe UI", Arial, Helvetica, sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15.96px;
  white-space: nowrap;
}

.chart-popup {
  border-width: thin;
  border-right: thin;
  border-right-style: solid;
  border-right-color: lightgrey;
}

.k-treeview .k-in {
  width: 155px;
  line-height: 20px;
  font-family: "Noto Sans", "Segoe UI", Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
}

.k-treeview .k-i-collapse:before {
  content: "\e013";
}

.k-treeview .k-i-expand:before {
  content: "\e015";
}

.k-treeview .k-top,
.k-treeview .k-mid,
.k-treeview .k-bot {
  cursor: pointer;
}

.k-appbar {
  padding: 0px 1rem;
  gap: 0px;
  z-index: 10000;
}

@media (max-width: 600px) {
  .k-appbar {
    padding: 0px 0.5rem;
  }
}

.k-appbar .k-appbar-section {
  margin: 0.5rem 0px;
}

.k-appbar-section#appbar-right-section1 {
  margin: auto 0px auto auto;
  height: 67px;
}

.k-appbar-section#appbar-right-section2 {
  margin: auto 0px auto 0px;
}

.k-appbar-section#appbar-right-section3 {
  margin: auto 0px auto 0px;
}

.k-treeview .k-in.k-state-focused {
  box-shadow: none;
}

#menu-options>button.k-button {
  border-color: rgb(255 255 255 / 8%);
  color: #656565;
  background-color: #ffffff;
  background-image: linear-gradient(#ffffff, #ffffff);
}

li.navigation-menu-item-horizontal>a>kendo-svg-icon.k-svg-icon {
  width: 18px;
  height: 18px;
}

#appbar-right-section1>ntw-navigation-menu>kendo-dropdownbutton {
  vertical-align: baseline;
}

.k-column-title {
  white-space: break-spaces !important;
  text-overflow: unset !important;
}

.k-filterable>.k-cell-inner {
  align-items: flex-end;
}

.k-filterable>.k-cell-inner .k-link {
  align-items: flex-end;
}

.k-columnmenu-item.k-state-selected {
  background-color: var(--color-brand-primary-default) !important;
}

.k-button.k-primary {
  border-color: var(--color-brand-primary-default) !important;
  background-color: var(--color-brand-primary-default) !important;
}

.k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap {
  border: none !important;
}

.k-dropdown .k-dropdown-wrap .k-select {
  background-color: white !important;
}

.k-filtercell .k-filtercell-operator>.k-button.k-clear-button-visible {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  background: none;
}

.k-input {
  border: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.08);
  background-color: white;
}

.k-breadcrumb .k-breadcrumb-link {
  color: black;
}

.k-stepper .k-progressbar {
  height: 2px;
  top: 20px;
}

.k-progressbar .k-selected {
  color: var(--color-brand-primary-active) !important;
  border-color: var(--color-brand-primary-active) !important;
  background-color: var(--color-brand-primary-active) !important;
}

.custom-step {
  width: 40px;
  height: 40px;
  // border: 2px solid red;
  border-width: thin;
  background-clip: padding-box;
  box-sizing: border-box;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-brand-primary-text-disabled);
  color: var(--color-brand-primary-text);
  font-family: "Noto Sans", "Segoe UI", Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  letter-spacing: 0;
}

.custom-step>kendo-svg-icon {
  transform: scale(1.5) !important;
}

.k-stepper .k-step-link {
  font-family: "Noto Sans", "Segoe UI", Arial, Helvetica, sans-serif;
  font-size: 12px;
}

.k-step-done .custom-step {
  background-color: var(--color-brand-primary-active);
}

.k-step:not(.k-step-done):not(.k-step-current) .custom-step {
  background-color: var(--color-brand-primary-text-disabled);
  color: var(--color-brand-primary-text);
}

.k-step-current .custom-step {
  background-color: var(--color-brand-primary-default);
}

.decima-mono-font {
  font-family: "Noto Sans Mono", "Segoe UI Mono", "Courier New", Courier,
    monospace;
}

.nimbus-sans-font {
  font-family: "Noto Sans", "Segoe UI", Arial, Helvetica, sans-serif;
}

.k-listview {
  max-height: 340px;
  border-style: none;
}

.list-item {
  width: 100%;
}

.header {
  display: flex;
  padding: 12px;
}

.title {
  flex: 1;
  font-size: 22px;
  font-weight: bold;
  color: var(--color-brand-primary-text-disabled);
  font-family: "Noto Sans", "Segoe UI", Arial, Helvetica, sans-serif;
}

.k-textbox-container.k-state-empty>.k-label,
.k-floating-label-container.k-state-empty>.k-label {
  left: 20px;
}

.k-switch-label-on,
.k-switch-label-off {
  font-size: 0px;
  background-color: var(--color-brand-primary-default) !important;
}

.k-switch-on .k-switch-container {
  color: #ffffff;
  background-color: var(--color-brand-primary-default);
}

.k-dialog-title {
  font-family: "Noto Sans", "Segoe UI", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.k-button-flat-primary,
.k-tabstrip-items-wrapper,
.k-item,
.k-tabstrip-items,
.k-link {
  color: inherit;
}

.k-active {
  color: #424242 !important;
}

.k-tabstrip-items-wrapper .k-item {
  color: #2a7ab0;
}