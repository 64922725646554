@use "./variables.scss" as *;

@mixin kendo-wizard--theme-base() {

    .k-wizard-step {
        &:focus,
        &.k-focus {
            outline-color: $kendo-wizard-step-focus-border;
        }
    }

}


@mixin kendo-wizard--theme() {
    @include kendo-wizard--theme-base();
}
