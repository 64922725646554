@use "sass:map";
@use "../functions/_string.import.scss" as *;

$_default-spacing: (
    0: 0px,
    1px: 1px,
    0.5: .125rem,
    1: .25rem,
    1.5: .375rem,
    2: .5rem,
    2.5: .625rem,
    3: .75rem,
    3.5: .875rem,
    4: 1rem,
    4.5: 1.125rem,
    5: 1.25rem,
    5.5: 1.375rem,
    6: 1.5rem,
    6.5: 1.625rem,
    7: 1.75rem,
    7.5: 1.875rem,
    8: 2rem,
    9: 2.25rem,
    10: 2.5rem,
    11: 2.75rem,
    12: 3rem,
    13: 3.25rem,
    14: 3.5rem,
    15: 3.75rem,
    16: 4rem,
    17: 4.25rem,
    18: 4.5rem,
    19: 4.75rem,
    20: 5rem,
    21: 5.25rem,
    22: 5.5rem,
    23: 5.75rem,
    24: 6rem,
    25: 7rem,
    26: 8rem,
    27: 9rem,
    28: 10rem,
    29: 11rem,
    30: 12rem
) !default;

/// The global default Spacing map.
/// @group spacing
$kendo-spacing: $_default-spacing !default;

$kendo-spacing: map.merge($_default-spacing, $kendo-spacing);


@function k-spacing($step) {
    $spacing: map.get($kendo-spacing, $step);
    $_step: k-escape-class-name( $step );
    @return var(--kendo-spacing-#{$_step}, #{$spacing});
}

// Generate a CSS variable for each value in the Spacing map
@mixin kendo-spacing--styles() {
    :root {
        @each $step, $spacing in $kendo-spacing {
            $_step: k-escape-class-name( $step );
            --kendo-spacing-#{$_step}: #{$spacing};
        }
    }
}

$kendo-sizing: map.merge( $kendo-spacing, (
    "auto": auto,
    "1/2": 50%,
    "1/3": 33.333333%,
    "2/3": 66.666667%,
    "1/4": 25%,
    "2/4": 50%,
    "3/4": 75%,
    "1/5": 20%,
    "2/5": 40%,
    "3/5": 60%,
    "4/5": 80%,
    "1/6": 16.666667%,
    "2/6": 33.333333%,
    "3/6": 50%,
    "4/6": 66.666667%,
    "5/6": 83.333333%,
    "1/12": 8.333333%,
    "2/12": 16.666667%,
    "3/12": 25%,
    "4/12": 33.333333%,
    "5/12": 41.666667%,
    "6/12": 50%,
    "7/12": 58.333333%,
    "8/12": 66.666667%,
    "9/12": 75%,
    "10/12": 83.333333%,
    "11/12": 91.666667%,
    "full": 100%,
    "min": min-content,
    "max": max-content,
    "fit": fit-content
)) !default;
