@use "../../color-system/_constants.scss" as *;
@use "./variables.scss" as *;
@use "../button/_variables.scss" as *;

@mixin kendo-pdf-viewer--layout-base() {

    // PDF Viewer
    .k-pdf-viewer {
        border-width: $kendo-pdf-viewer-border-width;
        border-style: solid;
        box-sizing: border-box;
        position: relative;
        outline: 0;
        font-family: $kendo-pdf-viewer-font-family;
        font-size: $kendo-pdf-viewer-font-size;
        line-height: $kendo-pdf-viewer-line-height;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $kendo-color-rgba-transparent;

        // Toolbar
        .k-toolbar {
            border-top-width: 0;
            border-right-width: 0;
            border-left-width: 0;
            border-color: inherit;
            flex: 0 0 auto;
            z-index: 2;
        }
        .k-toolbar .k-pager-wrap,
        .k-toolbar .k-pager {
            padding: 0;
            border-width: 0;
            color: inherit;
            background: none;
            overflow: visible;
        }
    }


    // Canvas
    .k-pdf-viewer-canvas {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        outline: none;

        &.k-enable-text-select {
            user-select: text;
            cursor: text;
        }

        &.k-enable-panning {
            cursor: grab !important; // stylelint-disable-line declaration-no-important

            span::selection {
                background-color: transparent;
            }
        }
    }

    // Pages
    .k-pdf-viewer-pages {
        flex: 1 1 auto;

        .k-page {
            position: relative;
            margin-block: $kendo-pdf-viewer-page-spacing;
            margin-inline: auto;
            z-index: 1;

            // Canvas
            .k-canvas-wrapper {
                overflow: hidden;
                width: 100%;
                height: 100%;

                canvas {
                    direction: ltr;
                }

                svg {
                    transform: none;
                }

                .k-highlight {
                    position: absolute;
                    mix-blend-mode: multiply;
                    fill-opacity: 1;

                }
                .k-highlight-outline {
                    position: absolute;
                    fill: none;
                    stroke-width: 2px;
                    stroke-dasharray: 2;
                }
            }


            .k-text-layer {
                position: absolute;
                top: 0;
                left: 0;
                opacity: .2;
                overflow: hidden;

                .k-marked-content > span,
                > span, br {
                    position: absolute;
                    line-height: $kendo-pdf-viewer-selection-line-height;
                    transform-origin: 0% 0%;
                    color: transparent;
                    white-space: pre;
                    cursor: text;
                }

                .k-marked-content {
                    top: 0;
                    height: 0;
                }

                .k-end-of-content {
                    display: block;
                    position: absolute;
                    inset: 100% 0 0;
                    z-index: 0;
                    cursor: default;
                    user-select: none;
                }

                .k-search-highlight-mark {
                    color: transparent;
                }

                .k-highlighting {
                    touch-action: none;
                }
            }

            .k-annotation-layer {
                position: absolute;
                top: 0;
                left: 0;
                transform-origin: 0 0;
                pointer-events: none;

                section {
                    position: absolute;
                    pointer-events: auto;
                }

                .k-annotation-text-content {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    color: transparent;
                    user-select: none;
                    pointer-events: none;
                }

                .k-link-annotation > a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            .k-annotation-editor-layer {
                background: transparent;
                position: absolute;
                inset: 0;
                transform-origin: 0 0;
                cursor: auto;

                .k-selected {
                    z-index: 100000 !important; // stylelint-disable-line declaration-no-important
                }

                .k-highlight-editor {
                    position: absolute;
                    background: transparent;
                    z-index: 1;
                    cursor: auto;
                    max-width: 100%;
                    max-height: 100%;
                    border: none;
                    outline: none;
                    pointer-events: none;
                    transform-origin: 0 0;

                    .k-internal {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        pointer-events: auto;
                    }

                    &.k-highlight-editor-disabled {
                        .k-internal {
                            pointer-events: none;
                        }
                    }
                }

                .k-free-text-editor {
                    position: absolute;
                    background: transparent;
                    z-index: 1;
                    transform-origin: 0 0;
                    cursor: text;
                    caret-color: $kendo-pdf-viewer-free-text-annotation-text;
                    max-width: 100%;
                    max-height: 100%;
                    border: solid 2px transparent;

                    &.k-selected.k-draggable {
                        cursor: move;;
                    }

                    &.k-selected .k-internal {
                        border-radius: 0px;
                        outline: 2px dashed $kendo-pdf-viewer-free-text-annotation-border;
                    }

                    .k-internal {
                        background: transparent;
                        border: none;
                        inset: 0;
                        overflow: visible;
                        white-space: nowrap;
                        user-select: none;
                    }

                    .k-internal:empty::before {
                        content: attr(default-content);
                    }
                }
            }

            .k-annotation-editor-layer-disabled {
                pointer-events: none;
            }
        }

        .k-blank-page {
            margin: 0;
            display: flex;
            flex-flow: column nowrap;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            background-color: inherit !important; // stylelint-disable-line declaration-no-important

            .k-upload,
            .k-dropzone,
            .k-dropzone-inner {
                border: 0;
                background: none;
            }
        }
    }

    // Search
    .k-pdf-viewer-canvas > .k-search-panel {
        width: max-content;
        margin-top: calc( (#{$kendo-button-calc-size} + ( 2 * #{$kendo-pdf-viewer-search-panel-border-width} ) + ( 2 * #{$kendo-pdf-viewer-search-panel-padding-y} )) * -1 );
        padding-block: $kendo-pdf-viewer-search-panel-padding-y;
        padding-inline: $kendo-pdf-viewer-search-panel-padding-x;
        border-width: $kendo-pdf-viewer-search-panel-border-width;
        border-style: solid;
        border-radius: $kendo-pdf-viewer-search-panel-border-radius;
        display: flex;
        gap: $kendo-pdf-viewer-search-panel-spacing;
        flex-flow: row nowrap;
        flex: 0 0 auto;
        justify-content: flex-start;
        align-items: center;
        z-index: 10;
        cursor: default;

        .k-search-dialog-draghandle {
            cursor: move;
            margin-left: 0;
        }

        .k-textbox {
            width: 10em;
            flex: none;

            .k-button {
                border-width: 0;
            }
        }

        .k-search-matches {
            display: inline-flex;
            gap: $kendo-pdf-viewer-search-panel-matches-spacing;
        }
    }

    // Annotation Toolbar
    .k-pdf-viewer-annotation-editor-toolbar > .k-toolbar {
        width: min-content;
        border-bottom-width: 0;
    }


    // Annotation Editor
    .k-pdf-viewer-annotation-editor {
        padding-block: $kendo-pdf-viewer-popup-padding-y;
        padding-inline: $kendo-pdf-viewer-popup-padding-x;

        .k-column-menu-group-header {
            padding-inline: 0;
        }

        .k-form-field {
            margin-top: 0;
        }

    }

}


@mixin kendo-pdf-viewer--layout() {
    @include kendo-pdf-viewer--layout-base();
}
