@use "../core/_index.scss" as *;
@use "../core/functions/index.import.scss" as *;

// Switch

/// The font family of the Switch.
/// @group switch
$kendo-switch-font-family: null !default;

/// The border width of the Switch track.
/// @group switch
$kendo-switch-track-border-width: 1px !default;

/// The border width of the Switch thumb.
/// @group switch
$kendo-switch-thumb-border-width: 1px !default;

/// The text transform of the Switch label.
/// @group switch
$kendo-switch-label-text-transform: uppercase !default;

/// The display of the Switch label.
/// @group switch
$kendo-switch-label-display: inline !default;

/// The map with the different Switch sizes.
/// @group switch
$kendo-switch-sizes: (
    sm: ( font-size: 10px, track-width: 52px, track-height: 26px, thumb-width: 26px, thumb-height: 26px, thumb-offset: 0px, label-offset: 4px ),
    md: ( font-size: 10px, track-width: 60px, track-height: 30px, thumb-width: 30px, thumb-height: 30px, thumb-offset: 0px, label-offset: 6px ),
    lg: ( font-size: 10px, track-width: 68px, track-height: 34px, thumb-width: 34px, thumb-height: 34px, thumb-offset: 0px, label-offset: 8px )
) !default;

/// The background of the track when the Switch is not checked.
/// @group switch
$kendo-switch-off-track-bg: $kendo-component-bg !default;
/// The text color of the track when the Switch is not checked.
/// @group switch
$kendo-switch-off-track-text: $kendo-component-text !default;
/// The border color of the track when the Switch is not checked.
/// @group switch
$kendo-switch-off-track-border: if($kendo-enable-color-system, k-color( border ), k-try-shade( $kendo-switch-off-track-bg, 8% )) !default;
/// The background gradient of the track when the Switch is not checked.
/// @group switch
$kendo-switch-off-track-gradient: null !default;

/// The background of the track when the hovered Switch is not checked.
/// @group switch
$kendo-switch-off-track-hover-bg: null !default;
/// The text color of the track when the hovered Switch is not checked.
/// @group switch
$kendo-switch-off-track-hover-text: null !default;
/// The border color of the track when the hovered Switch is not checked.
/// @group switch
$kendo-switch-off-track-hover-border: null !default;
/// The background gradient of the track when the hovered Switch is not checked.
/// @group switch
$kendo-switch-off-track-hover-gradient: null !default;

/// The background of the track when the focused Switch is not checked.
/// @group switch
$kendo-switch-off-track-focus-bg: null !default;
/// The text color of the track when the focused Switch is not checked.
/// @group switch
$kendo-switch-off-track-focus-text: null !default;
/// The border color of the track when the focused Switch is not checked.
/// @group switch
$kendo-switch-off-track-focus-border: null !default;
/// The background gradient of the track when the focused Switch is not checked.
/// @group switch
$kendo-switch-off-track-focus-gradient: null !default;
/// The ring around the track when the focused Switch is not checked.
/// @group switch
$kendo-switch-off-track-focus-ring: 2px solid if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 8%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ) , .08 )) !default;

/// The background of the track when the disabled Switch is not checked.
/// @group switch
$kendo-switch-off-track-disabled-bg: null !default;
/// The text color of the track when the disabled Switch is not checked.
/// @group switch
$kendo-switch-off-track-disabled-text: null !default;
/// The border color of the track when the disabled Switch is not checked.
/// @group switch
$kendo-switch-off-track-disabled-border: null !default;
/// The background gradient of the track when the disabled Switch is not checked.
/// @group switch
$kendo-switch-off-track-disabled-gradient: null !default;

/// The background of the thumb when the Switch is not checked.
/// @group switch
$kendo-switch-off-thumb-bg: $kendo-base-bg !default;
/// The text color of the thumb when the Switch is not checked.
/// @group switch
$kendo-switch-off-thumb-text: $kendo-base-text !default;
/// The border color of the thumb when the Switch is not checked.
/// @group switch
$kendo-switch-off-thumb-border: $kendo-base-border !default;
/// The background gradient of the thumb when the Switch is not checked.
/// @group switch
$kendo-switch-off-thumb-gradient: null !default;

/// The background of the thumb when the hovered Switch is not checked.
/// @group switch
$kendo-switch-off-thumb-hover-bg: null !default;
/// The text color of the thumb when the hovered Switch is not checked.
/// @group switch
$kendo-switch-off-thumb-hover-text: null !default;
/// The border color of the thumb when the hovered Switch is not checked.
/// @group switch
$kendo-switch-off-thumb-hover-border: null !default;
/// The background gradient of the thumb when the hovered Switch is not checked.
/// @group switch
$kendo-switch-off-thumb-hover-gradient: null !default;


/// The background of the track when the Switch is checked.
/// @group switch
$kendo-switch-on-track-bg: $kendo-color-primary !default;
/// The text color of the track when the Switch is checked.
/// @group switch
$kendo-switch-on-track-text: if($kendo-enable-color-system, k-color( on-primary ), k-contrast-legacy( $kendo-switch-on-track-bg )) !default;
/// The border color of the track when the Switch is checked.
/// @group switch
$kendo-switch-on-track-border: $kendo-switch-on-track-bg !default;
/// The background gradient of the track when the Switch is checked.
/// @group switch
$kendo-switch-on-track-gradient: null !default;

/// The background of the track when the hovered Switch is checked.
/// @group switch
$kendo-switch-on-track-hover-bg: null !default;
/// The text color of the track when the hovered Switch is checked.
/// @group switch
$kendo-switch-on-track-hover-text: null !default;
/// The border color of the track when the hovered Switch is checked.
/// @group switch
$kendo-switch-on-track-hover-border: null !default;
/// The background gradient of the track when the hovered Switch is checked.
/// @group switch
$kendo-switch-on-track-hover-gradient: null !default;

/// The background of the track when the focused Switch is checked.
/// @group switch
$kendo-switch-on-track-focus-bg: null !default;
/// The text color of the track when the focused Switch is checked.
/// @group switch
$kendo-switch-on-track-focus-text: null !default;
/// The border color of the track when the focused Switch is checked.
/// @group switch
$kendo-switch-on-track-focus-border: null !default;
/// The background gradient of the track when the focused Switch is checked.
/// @group switch
$kendo-switch-on-track-focus-gradient: null !default;
/// The ring around the track when the focused Switch is checked.
/// @group switch
$kendo-switch-on-track-focus-ring: 2px solid if($kendo-enable-color-system, color-mix(in srgb, k-color( primary ) 25%, transparent), rgba( $kendo-switch-on-track-border, .25 )) !default;

/// The background of the track when the disabled Switch is checked.
/// @group switch
$kendo-switch-on-track-disabled-bg: null !default;
/// The text color of the track when the disabled Switch is checked.
/// @group switch
$kendo-switch-on-track-disabled-text: null !default;
/// The border color of the track when the disabled Switch is checked.
/// @group switch
$kendo-switch-on-track-disabled-border: null !default;
/// The background gradient of the track when the disabled Switch is checked.
/// @group switch
$kendo-switch-on-track-disabled-gradient: null !default;

/// The background of the thumb when the Switch is checked.
/// @group switch
$kendo-switch-on-thumb-bg: $kendo-base-bg !default;
/// The text color of the thumb when the Switch is checked.
/// @group switch
$kendo-switch-on-thumb-text: $kendo-base-text !default;
/// The border color of the thumb when the Switch is checked.
/// @group switch
$kendo-switch-on-thumb-border: $kendo-base-border !default;
/// The background gradient of the thumb when the Switch is checked.
/// @group switch
$kendo-switch-on-thumb-gradient: null !default;

/// The background of the thumb when the hovered Switch is checked.
/// @group switch
$kendo-switch-on-thumb-hover-bg: null !default;
/// The text color of the thumb when the hovered Switch is checked.
/// @group switch
$kendo-switch-on-thumb-hover-text: null !default;
/// The border color of the thumb when the hovered Switch is checked.
/// @group switch
$kendo-switch-on-thumb-hover-border: null !default;
/// The background gradient of the thumb when the hovered Switch is checked.
/// @group switch
$kendo-switch-on-thumb-hover-gradient: null !default;

@forward "@progress/kendo-theme-core/scss/components/switch/_variables.scss" with (
    $kendo-switch-font-family: $kendo-switch-font-family,
    $kendo-switch-track-border-width: $kendo-switch-track-border-width,
    $kendo-switch-thumb-border-width: $kendo-switch-thumb-border-width,
    $kendo-switch-label-text-transform: $kendo-switch-label-text-transform,
    $kendo-switch-label-display: $kendo-switch-label-display,
    $kendo-switch-sizes: $kendo-switch-sizes,
    $kendo-switch-off-track-bg: $kendo-switch-off-track-bg,
    $kendo-switch-off-track-text: $kendo-switch-off-track-text,
    $kendo-switch-off-track-border: $kendo-switch-off-track-border,
    $kendo-switch-off-track-gradient: $kendo-switch-off-track-gradient,
    $kendo-switch-off-track-hover-bg: $kendo-switch-off-track-hover-bg,
    $kendo-switch-off-track-hover-text: $kendo-switch-off-track-hover-text,
    $kendo-switch-off-track-hover-border: $kendo-switch-off-track-hover-border,
    $kendo-switch-off-track-hover-gradient: $kendo-switch-off-track-hover-gradient,
    $kendo-switch-off-track-focus-bg: $kendo-switch-off-track-focus-bg,
    $kendo-switch-off-track-focus-text: $kendo-switch-off-track-focus-text,
    $kendo-switch-off-track-focus-border: $kendo-switch-off-track-focus-border,
    $kendo-switch-off-track-focus-gradient: $kendo-switch-off-track-focus-gradient,
    $kendo-switch-off-track-focus-ring: $kendo-switch-off-track-focus-ring,
    $kendo-switch-off-track-disabled-bg: $kendo-switch-off-track-disabled-bg,
    $kendo-switch-off-track-disabled-text: $kendo-switch-off-track-disabled-text,
    $kendo-switch-off-track-disabled-border: $kendo-switch-off-track-disabled-border,
    $kendo-switch-off-track-disabled-gradient: $kendo-switch-off-track-disabled-gradient,
    $kendo-switch-off-thumb-bg: $kendo-switch-off-thumb-bg,
    $kendo-switch-off-thumb-text: $kendo-switch-off-thumb-text,
    $kendo-switch-off-thumb-border: $kendo-switch-off-thumb-border,
    $kendo-switch-off-thumb-gradient: $kendo-switch-off-thumb-gradient,
    $kendo-switch-off-thumb-hover-bg: $kendo-switch-off-thumb-hover-bg,
    $kendo-switch-off-thumb-hover-text: $kendo-switch-off-thumb-hover-text,
    $kendo-switch-off-thumb-hover-border: $kendo-switch-off-thumb-hover-border,
    $kendo-switch-off-thumb-hover-gradient: $kendo-switch-off-thumb-hover-gradient,
    $kendo-switch-on-track-bg: $kendo-switch-on-track-bg,
    $kendo-switch-on-track-text: $kendo-switch-on-track-text,
    $kendo-switch-on-track-border: $kendo-switch-on-track-border,
    $kendo-switch-on-track-gradient: $kendo-switch-on-track-gradient,
    $kendo-switch-on-track-hover-bg: $kendo-switch-on-track-hover-bg,
    $kendo-switch-on-track-hover-text: $kendo-switch-on-track-hover-text,
    $kendo-switch-on-track-hover-border: $kendo-switch-on-track-hover-border,
    $kendo-switch-on-track-hover-gradient: $kendo-switch-on-track-hover-gradient,
    $kendo-switch-on-track-focus-bg: $kendo-switch-on-track-focus-bg,
    $kendo-switch-on-track-focus-text: $kendo-switch-on-track-focus-text,
    $kendo-switch-on-track-focus-border: $kendo-switch-on-track-focus-border,
    $kendo-switch-on-track-focus-gradient: $kendo-switch-on-track-focus-gradient,
    $kendo-switch-on-track-focus-ring: $kendo-switch-on-track-focus-ring,
    $kendo-switch-on-track-disabled-bg: $kendo-switch-on-track-disabled-bg,
    $kendo-switch-on-track-disabled-text: $kendo-switch-on-track-disabled-text,
    $kendo-switch-on-track-disabled-border: $kendo-switch-on-track-disabled-border,
    $kendo-switch-on-track-disabled-gradient: $kendo-switch-on-track-disabled-gradient,
    $kendo-switch-on-thumb-bg: $kendo-switch-on-thumb-bg,
    $kendo-switch-on-thumb-text: $kendo-switch-on-thumb-text,
    $kendo-switch-on-thumb-border: $kendo-switch-on-thumb-border,
    $kendo-switch-on-thumb-gradient: $kendo-switch-on-thumb-gradient,
    $kendo-switch-on-thumb-hover-bg: $kendo-switch-on-thumb-hover-bg,
    $kendo-switch-on-thumb-hover-text: $kendo-switch-on-thumb-hover-text,
    $kendo-switch-on-thumb-hover-border: $kendo-switch-on-thumb-hover-border,
    $kendo-switch-on-thumb-hover-gradient: $kendo-switch-on-thumb-hover-gradient
);
