@use "sass:math";
@use "../../color-system/_constants.scss" as *;
@use "./variables.scss" as *;
@use "../toolbar/_variables.scss" as *;

@mixin kendo-image-editor--layout-base() {

    // Image Editor
    .k-imageeditor {
        margin-block: $kendo-image-editor-margin-y;
        margin-inline: $kendo-image-editor-margin-x;
        padding-block: $kendo-image-editor-padding-y;
        padding-inline: $kendo-image-editor-padding-x;
        border-width: $kendo-image-editor-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $kendo-image-editor-font-family;
        font-size: $kendo-image-editor-font-size;
        line-height: $kendo-image-editor-line-height;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $kendo-color-rgba-transparent;

        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }
    }


    // Image editor toolbar
    .k-imageeditor-toolbar {
        border-width: 0;
        border-bottom-width: $kendo-toolbar-border-width;
    }


    // Content
    .k-imageeditor-content {
        border-width: 0;
        border-style: solid;
        border-color: inherit;
        display: flex;
        flex-flow: row nowrap;
    }


    // Canvas
    .k-imageeditor-canvas-container {
        flex: 1 1 100%;
        display: flex;
        flex-flow: row nowrap;
        overflow: auto;
    }
    .k-imageeditor-canvas {
        margin: auto;
        display: flex;
        flex-direction: column;
        position: relative;
    }


    // Action Pane
    .k-imageeditor-action-pane {
        padding-block: $kendo-image-editor-action-pane-padding-y;
        padding-inline: $kendo-image-editor-action-pane-padding-x;
        width: $kendo-image-editor-action-pane-width;
        border-width: 0;
        border-left-width: $kendo-image-editor-content-border-width;
        border-style: solid;
        border-color: inherit;
        box-sizing: border-box;
        flex: none;
        overflow-y: auto;

        .k-input-inner,
        .k-input-value-text {
            text-overflow: clip;
        }
    }


    // Crop Tool
    .k-imageeditor-crop-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .k-imageeditor-crop {
        width: 100%;
        height: 100%;
        border-width: $kendo-image-editor-crop-border-width;
        border-style: $kendo-image-editor-crop-border-style;
        box-sizing: border-box;
        position: absolute;

        .k-resize-handle {
            margin-block: $kendo-image-editor-resize-handle-margin-y;
            margin-inline: $kendo-image-editor-resize-handle-margin-x;
            border-style: solid;
            border-width: $kendo-image-editor-resize-handle-border-width;
        }

        .k-resize-sw,
        .k-resize-se,
        .k-resize-nw,
        .k-resize-ne {
            width: $kendo-image-editor-resize-handle-size;
            height: $kendo-image-editor-resize-handle-size;
        }

        .k-resize-n,
        .k-resize-s {
            left: calc( 50% - (#{math.div( $kendo-image-editor-resize-handle-size, 2 )}) );
            width: $kendo-image-editor-resize-handle-size;
            height: 0;
        }

        .k-resize-w,
        .k-resize-e {
            top: calc( 50% - (#{math.div( $kendo-image-editor-resize-handle-size, 2 )}) );
            width: 0;
            height: $kendo-image-editor-resize-handle-size;
        }

        .k-resize-ne {
            transform: rotate(-90deg);
        }

        .k-resize-nw {
            transform: rotate(-180deg);
        }


        .k-resize-sw {
            transform: rotate(90deg);
        }

        .k-resize-n {
            top: 0;
        }

        .k-resize-s {
            bottom: 0;
        }

        .k-resize-w {
            left: 0;
        }

        .k-resize-e {
            right: 0;
        }
    }

}


@mixin kendo-image-editor--layout() {
    @include kendo-image-editor--layout-base();
}
