@use "../../mixins/index.import.scss" as *;
@use "./variables.scss" as *;

@mixin kendo-image-editor--theme-base() {

    // Image Editor
    .k-imageeditor {
        @include fill(
            $kendo-image-editor-text,
            $kendo-image-editor-bg,
            $kendo-image-editor-border
        );
    }

    // Content
    .k-imageeditor-content {
        @include fill(
            $kendo-image-editor-content-text,
            $kendo-image-editor-content-bg,
            $kendo-image-editor-content-border
        );
    }

    // Canvas
    .k-imageeditor-canvas {
        @include checkerboard-gradient();
    }

    // Action Pane
    .k-imageeditor-action-pane {
        @include fill(
            $kendo-image-editor-action-pane-text,
            $kendo-image-editor-action-pane-bg,
            $kendo-image-editor-action-pane-border
        );
    }

    // Crop Tool
    .k-imageeditor-crop {
        @include fill(
            $kendo-image-editor-crop-text,
            $kendo-image-editor-crop-bg,
            $kendo-image-editor-crop-border
        );
    }

    // Crop Overlay
    .k-imageeditor-crop-overlay {
        @include fill (
            $bg: $kendo-image-editor-crop-overlay-bg
        );
    }

}


@mixin kendo-image-editor--theme() {
    @include kendo-image-editor--theme-base();
}
