@use "sass:map";
@use "./variables.scss" as *;

@mixin kendo-signature--layout-base() {

    .k-signature {
        width: $kendo-signature-width;
        min-height: $kendo-signature-height;
        position: relative;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &.k-signature-maximized {
            width: $kendo-signature-maximized-width;
            height: $kendo-signature-maximized-height;

            > .k-signature-line {
                border-bottom-width: $kendo-signature-maximized-line-width;
            }
        }
    }

    .k-signature-actions {
        display: flex;
        width: min-content;
        margin-inline-start: auto;
        gap: $kendo-signature-actions-gap;
        z-index: 2;
    }

    .k-signature-canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 1;
        outline: none;
    }

    .k-signature-line {
        position: absolute;
        bottom: $kendo-signature-line-bottom-offset;
        z-index: 2;
        pointer-events: none;
        border-bottom-width: $kendo-signature-line-width;
        border-bottom-style: $kendo-signature-line-style;
    }

    // Sizes
    @each $size, $size-props in $kendo-signature-sizes {
        $_padding-x: map.get($size-props, padding-x);
        $_padding-y: map.get($size-props, padding-y);
        $_line-size: map.get($size-props, line-size);
        $_line-offset: map.get($size-props, line-offset);

        .k-signature-#{$size} {
            padding-inline: $_padding-x;
            padding-block: $_padding-y;

            .k-signature-line {
                width: $_line-size;
            }
        }
    }

    .k-signature-lg {
        min-height: $kendo-signature-lg-min-height;
    }

}


@mixin kendo-signature--layout() {
    @include kendo-signature--layout-base();
}
