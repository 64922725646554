@use "../../mixins/index.import.scss" as *;
@use "../../color-system/_swatch-legacy.scss" as *;
@use "./variables.scss" as *;

@mixin kendo-listbox--theme-base() {

    // Listbox
    .k-listbox {
        .k-list-scroller {
            @include fill(
                $kendo-listbox-text,
                $kendo-listbox-bg,
                $kendo-listbox-border
            );
        }
        .k-drop-hint {
            border-top-color: $kendo-color-primary;
        }
    }

}


@mixin kendo-listbox--theme() {
    @include kendo-listbox--theme-base();
}
