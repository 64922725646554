@mixin kendo-core--styles--selection() {

    // Marquee
    .k-marquee {
        position: absolute;
        z-index: 100000;
    }

    .k-marquee-color,
    .k-marquee-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .k-marquee-color {
        color: var( --kendo-marquee-text, unset );
        background-color: var( --kendo-marquee-bg, unset );
        border-color: var( --kendo-marquee-border, unset );
        opacity: .6;
    }
    .k-marquee-text {
        color: var( --kendo-marquee-text, unset );
    }

    // Text selection
    .k-text-selection {
        ::selection {
            background-color: var( --kendo-text-selection-bg, unset );
            color: var( --kendo-marquee-text, unset );
        }
    }

}
