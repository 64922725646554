@use "@progress/kendo-theme-core/scss/index.scss" as *;
@use "../functions/index.import.scss" as *;

// Primary colors
$kendo-color-primary: if($kendo-enable-color-system, k-color( primary ), #ff6358 ) !default;
$kendo-color-primary-lighter: if($kendo-enable-color-system, null, k-color-tint($kendo-color-primary, 2)) !default;
$kendo-color-primary-darker: if($kendo-enable-color-system, null, k-color-shade($kendo-color-primary, 2)) !default;
$kendo-color-primary-contrast: if($kendo-enable-color-system, k-color( on-primary ), k-contrast-legacy($kendo-color-primary) ) !default;

// Secondary colors
$kendo-color-secondary: if($kendo-enable-color-system, k-color( secondary ), #666666 ) !default;
$kendo-color-secondary-lighter: if($kendo-enable-color-system, null, k-color-tint($kendo-color-secondary, 2)) !default;
$kendo-color-secondary-darker: if($kendo-enable-color-system, null, k-color-shade($kendo-color-secondary, 2)) !default;
$kendo-color-secondary-contrast: if($kendo-enable-color-system, k-color( on-secondary ), k-contrast-legacy($kendo-color-secondary) ) !default;

// Tertiary colors
$kendo-color-tertiary: if($kendo-enable-color-system, k-color( tertiary ), #03a9f4 ) !default;
$kendo-color-tertiary-lighter: if($kendo-enable-color-system, null, k-color-tint($kendo-color-tertiary, 2)) !default;
$kendo-color-tertiary-darker: if($kendo-enable-color-system, null, k-color-shade($kendo-color-tertiary, 2)) !default;
$kendo-color-tertiary-contrast: if($kendo-enable-color-system, k-color( on-tertiary ), k-contrast-legacy($kendo-color-tertiary) ) !default;

// Info colors
$kendo-color-info: if($kendo-enable-color-system, k-color( info ), #0058e9 ) !default;
$kendo-color-info-lighter: if($kendo-enable-color-system, null, k-color-tint($kendo-color-info, 2)) !default;
$kendo-color-info-darker: if($kendo-enable-color-system, null, k-color-shade($kendo-color-info, 2)) !default;

// Success colors
$kendo-color-success: if($kendo-enable-color-system, k-color( success ), #37b400 ) !default;
$kendo-color-success-lighter: if($kendo-enable-color-system, null, k-color-tint($kendo-color-success, 2)) !default;
$kendo-color-success-darker: if($kendo-enable-color-system, null, k-color-shade($kendo-color-success, 2)) !default;

// Warning colors
$kendo-color-warning: if($kendo-enable-color-system, k-color( warning ), #ffc000 ) !default;
$kendo-color-warning-lighter: if($kendo-enable-color-system, null, k-color-tint($kendo-color-warning, 2)) !default;
$kendo-color-warning-darker: if($kendo-enable-color-system, null, k-color-shade($kendo-color-warning, 2)) !default;

// Error colors
$kendo-color-error: if($kendo-enable-color-system, k-color( error ), #f31700 ) !default;
$kendo-color-error-lighter: if($kendo-enable-color-system, null, k-color-tint($kendo-color-error, 2)) !default;
$kendo-color-error-darker: if($kendo-enable-color-system, null, k-color-shade($kendo-color-error, 2)) !default;


$kendo-color-dark: if($kendo-enable-color-system, k-color( dark ), #424242 ) !default;

// Light colors
$kendo-color-light: if($kendo-enable-color-system, k-color( light ), #ebebeb ) !default;

// Inverse colors
$kendo-color-inverse: if($kendo-is-dark-theme, $kendo-color-light, $kendo-color-dark) !default;

// Body styles
$kendo-body-bg: if($kendo-enable-color-system, k-color( app-surface ), #ffffff ) !default;
$kendo-body-text: if($kendo-enable-color-system,  k-color( on-app-surface ), #424242 ) !default;

// App styles
$kendo-app-bg: if($kendo-enable-color-system, k-color( surface ), k-try-shade($kendo-body-bg, 0.25) ) !default;
$kendo-app-text: $kendo-body-text !default;
$kendo-app-border: if($kendo-enable-color-system, k-color( border ), rgba(#000000, 0.08) ) !default;

// Component styles
$kendo-component-bg: if($kendo-enable-color-system, k-color( surface-alt ), $kendo-body-bg ) !default;
$kendo-component-text: $kendo-body-text  !default;
$kendo-component-border: if($kendo-enable-color-system, $kendo-app-border, rgba(if($kendo-is-dark-theme, $kendo-color-white, #000000), 0.08) ) !default;

$kendo-base-bg: if($kendo-enable-color-system, k-color( surface ), #fafafa ) !default;
$kendo-base-text: $kendo-body-text !default;
$kendo-base-border: $kendo-app-border !default;
$kendo-base-gradient: if($kendo-enable-color-system, null, (rgba(black, 0), rgba(black, 0.02)) ) !default;

// States styles
$kendo-hover-bg: if($kendo-enable-color-system, k-color( base-hover ), k-try-shade($kendo-base-bg, 0.5) ) !default;
$kendo-hover-text: $kendo-base-text !default;
$kendo-hover-border: $kendo-base-border !default;
$kendo-hover-gradient: $kendo-base-gradient !default;

$kendo-selected-bg: $kendo-color-primary !default;
$kendo-selected-text: $kendo-color-primary-contrast !default;
$kendo-selected-border: $kendo-base-border !default;
$kendo-selected-gradient: $kendo-base-gradient !default;

$kendo-selected-hover-bg: if($kendo-enable-color-system, k-color( primary-hover ), k-try-shade($kendo-selected-bg, 0.5) ) !default;
$kendo-selected-hover-text: $kendo-selected-text !default;
$kendo-selected-hover-border: $kendo-base-border !default;
$kendo-selected-hover-gradient: $kendo-base-gradient  !default;

$kendo-component-header-bg: $kendo-base-bg !default;
$kendo-component-header-text: $kendo-base-text !default;
$kendo-component-header-border: $kendo-base-border !default;
$kendo-component-header-gradient: null !default;

$kendo-focus-outline: null !default;

$kendo-subtle-text: if($kendo-enable-color-system, k-color( subtle ), #666666 ) !default;

// Link
$kendo-link-text: $kendo-color-primary !default;
$kendo-link-hover-text: if($kendo-enable-color-system, k-color( primary-hover ), $kendo-color-primary-darker) !default;

// Validator
$kendo-invalid-bg: null !default;
$kendo-invalid-text: $kendo-color-error !default;
$kendo-invalid-border: $kendo-color-error !default;
$kendo-invalid-shadow: null !default;

$kendo-valid-bg: null !default;
$kendo-valid-text: $kendo-color-success !default;
$kendo-valid-border: $kendo-color-success !default;
$kendo-valid-shadow: null !default;

// Disabled Styling

/// The background color of disabled items.
$kendo-disabled-bg: null !default;
/// The text color of disabled items.
$kendo-disabled-text: null !default;
/// The border color of disabled items.
$kendo-disabled-border: null !default;
/// The opacity of disabled items.
$kendo-disabled-opacity: .6 !default;
/// The filter of disabled items.
$kendo-disabled-filter: grayscale(.1) !default;

$kendo-disabled-styling: (
    bg: $kendo-disabled-bg,
    color: $kendo-disabled-text,
    border: $kendo-disabled-border,
    opacity: $kendo-disabled-opacity,
    filter: $kendo-disabled-filter
) !default;


$kendo-palettes: () !default;

$kendo-theme-colors: (
    "primary": $kendo-color-primary,
    "secondary": $kendo-color-secondary,
    "tertiary": $kendo-color-tertiary,
    "info": $kendo-color-info,
    "success": $kendo-color-success,
    "warning": $kendo-color-warning,
    "error": $kendo-color-error,
    "dark": $kendo-color-dark,
    "light": $kendo-color-light,
    "inverse": $kendo-color-inverse,
) !default;

@forward "@progress/kendo-theme-core/scss/color-system/_swatch-legacy.scss" with (
    $kendo-color-primary: $kendo-color-primary,
    $kendo-color-primary-lighter: $kendo-color-primary-lighter,
    $kendo-color-primary-darker: $kendo-color-primary-darker,
    $kendo-color-primary-contrast: $kendo-color-primary-contrast,
    $kendo-color-secondary: $kendo-color-secondary,
    $kendo-color-secondary-lighter: $kendo-color-secondary-lighter,
    $kendo-color-secondary-darker: $kendo-color-secondary-darker,
    $kendo-color-secondary-contrast: $kendo-color-secondary-contrast,
    $kendo-color-tertiary: $kendo-color-tertiary,
    $kendo-color-tertiary-lighter: $kendo-color-tertiary-lighter,
    $kendo-color-tertiary-darker: $kendo-color-tertiary-darker,
    $kendo-color-tertiary-contrast: $kendo-color-tertiary-contrast,
    $kendo-color-info: $kendo-color-info,
    $kendo-color-info-lighter: $kendo-color-info-lighter,
    $kendo-color-info-darker: $kendo-color-info-darker,
    $kendo-color-success: $kendo-color-success,
    $kendo-color-success-lighter: $kendo-color-success-lighter,
    $kendo-color-success-darker: $kendo-color-success-darker,
    $kendo-color-warning: $kendo-color-warning,
    $kendo-color-warning-lighter: $kendo-color-warning-lighter,
    $kendo-color-warning-darker: $kendo-color-warning-darker,
    $kendo-color-error: $kendo-color-error,
    $kendo-color-error-lighter: $kendo-color-error-lighter,
    $kendo-color-error-darker: $kendo-color-error-darker,
    $kendo-color-dark: $kendo-color-dark,
    $kendo-color-light: $kendo-color-light,
    $kendo-color-inverse: $kendo-color-inverse,
    $kendo-body-bg: $kendo-body-bg,
    $kendo-body-text: $kendo-body-text,
    $kendo-app-bg: $kendo-app-bg,
    $kendo-app-text: $kendo-app-text,
    $kendo-app-border: $kendo-app-border,
    $kendo-component-bg: $kendo-component-bg,
    $kendo-component-text: $kendo-component-text,
    $kendo-component-border: $kendo-component-border,
    $kendo-base-bg: $kendo-base-bg,
    $kendo-base-text: $kendo-base-text,
    $kendo-base-border: $kendo-base-border,
    $kendo-base-gradient: $kendo-base-gradient,
    $kendo-hover-bg: $kendo-hover-bg,
    $kendo-hover-text: $kendo-hover-text,
    $kendo-hover-border: $kendo-hover-border,
    $kendo-hover-gradient: $kendo-hover-gradient,
    $kendo-selected-bg: $kendo-selected-bg,
    $kendo-selected-text: $kendo-selected-text,
    $kendo-selected-border: $kendo-selected-border,
    $kendo-selected-gradient: $kendo-selected-gradient,
    $kendo-selected-hover-bg: $kendo-selected-hover-bg,
    $kendo-selected-hover-text: $kendo-selected-hover-text,
    $kendo-selected-hover-border: $kendo-selected-hover-border,
    $kendo-selected-hover-gradient: $kendo-selected-hover-gradient,
    $kendo-component-header-bg: $kendo-component-header-bg,
    $kendo-component-header-text: $kendo-component-header-text,
    $kendo-component-header-border: $kendo-component-header-border,
    $kendo-component-header-gradient: $kendo-component-header-gradient,
    $kendo-focus-outline: $kendo-focus-outline,
    $kendo-subtle-text: $kendo-subtle-text,
    $kendo-link-text: $kendo-link-text,
    $kendo-link-hover-text: $kendo-link-hover-text,
    $kendo-invalid-bg: $kendo-invalid-bg,
    $kendo-invalid-text: $kendo-invalid-text,
    $kendo-invalid-border: $kendo-invalid-border,
    $kendo-invalid-shadow: $kendo-invalid-shadow,
    $kendo-valid-bg: $kendo-valid-bg,
    $kendo-valid-text: $kendo-valid-text,
    $kendo-valid-border: $kendo-valid-border,
    $kendo-valid-shadow: $kendo-valid-shadow,
    $kendo-disabled-bg: $kendo-disabled-bg,
    $kendo-disabled-text: $kendo-disabled-text,
    $kendo-disabled-border: $kendo-disabled-border,
    $kendo-disabled-opacity: $kendo-disabled-opacity,
    $kendo-disabled-filter: $kendo-disabled-filter,
    $kendo-disabled-styling: $kendo-disabled-styling,
    $kendo-theme-colors: $kendo-theme-colors
);
