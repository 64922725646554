@use "./variables.scss" as *;

@mixin kendo-no-data--theme-base() {

    .k-no-data {
        color: $kendo-no-data-text;
    }

}


@mixin kendo-no-data--theme() {
    @include kendo-no-data--theme-base();
}
